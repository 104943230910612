
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import { addMonths, addDays } from 'date-fns';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IBilling, IBillingDetail, IBillingSearch, IBillingDeliverySearch, IBillingDashboardDate, 
    IBillingDashboardMonth, IFinanceDashboard, IEmployeeBillingDashboard, 
    ICommissionDistribution, IBillingDelivery } from "../models/Billing";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { globalConfig } from 'config';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    const {id: onlineCustomerId} = useRecoilValue(currentUserSessionAtom);    

    const createBillingForPayPal = async (billing: IBilling)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/public/finance/online-billing/v1/${globalConfig.get().applicationApiToken}/create-for-paypal`, billing)).data;       
        
    const updateBillingForPayPal = async (billing: IBilling)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/public/finance/online-billing/v1/${globalConfig.get().applicationApiToken}/update-for-paypal`, billing)).data; 
    
    const validateBillingForPayPal = async (validateRequest: any)  =>       
      await (await axios.post(`${globalConfig.get().apiUrl}/public/finance/online-billing/v1/${globalConfig.get().applicationApiToken}/validate-for-paypal`, validateRequest)).data; 

    const getMyLastBillings = async () : Promise<IBilling[]> => {
      
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/public/finance/online-billing/v1/${globalConfig.get().applicationApiToken}/get-billings`));
      return await data;
    }

    const getBilling = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/billing/get-billing/${id}`));
      return await data;
    }

    const createBillingDelivery = async (billingDelivery: IBillingDelivery)  =>       
        await (await axios.post('/api/finance/billingDelivery/create', billingDelivery)).data;
    
    const updateBillingDelivery = async (billingDelivery: IBillingDelivery)  =>       
        await (await axios.post('/api/finance/billingDelivery/update', billingDelivery)).data;

    const deliverBillingDetail = async (data: {id: number, deliveryDate: Date})  =>       
        await (await axios.post('/api/finance/billing/deliver-billing-detail', data)).data;
        

    const getBillings = async (criteria: IBillingSearch, pagination?: IPagination) : Promise<IBilling[]> => {
      
      const {startDate, endDate, customerName, customerPortable, reference} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/billing/get-billings?customerName=${customerName}` +
        `&customerPortable=${customerPortable}&reference=${reference}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getCommissionDistributionsByBilling = async (billingId  : number ) : Promise<ICommissionDistribution[]>  => {
      const {data} = (await axios.get(`/api/finance/billing/get-commissionDistributions-by-billing?billingId=${billingId}`));
      return await data;
    }

    const evaluateAddedProduct = async (billinAddedProduct: any): Promise<IResult<IBillingDetail>>  =>       
        await (await axios.post('/api/finance/billing/evaluate-added-product', billinAddedProduct)).data; 


    const getBillingsByRegistration = async (registrationId: number) : Promise<IBilling[]> => {
            
      const {data} = (await axios.get(`/api/finance/billing/get-billings-by-registration?registrationId=${registrationId}`));
      return await data;
    }

    const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<IBillingDashboardDate> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/finance/billing/get-billing-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }

    const getMonthDashboard = async (year: number, month: number) : Promise<IBillingDashboardMonth> => {
      
      const {data} = (await axios.get(`/api/finance/billing/get-billing-month-dashboard?year=${year}&month=${month}`));
      return await data;
    }

    const getFinanceDashboard = async (year: number, month: number) : Promise<IFinanceDashboard> => {
      
      const {data} = (await axios.get(`/api/finance/billing/get-finance-dashboard?year=${year}&month=${month}`));
      return await data;
    }

    const getEmployeeBillingDashboard = async (date: Date) : Promise<IEmployeeBillingDashboard> => {
      
      const sDate = encodeURIComponent(date.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/finance/billing/get-employee-billing-dashboard?sDate=${sDate}`));
      return await data;
    }

    const getLastServicesByPerson = async (personId: number) : Promise<{packagingName: string, serviceName: string, issueDate: Date}[]> => {
      
      const {data} = (await axios.get(`/api/finance/billing/get-last-billings-by-person?personId=${personId}`));
      return await data;
    }

    const getBillingDeliveriesByBilling = async (billingId: number, status: string) : Promise<IBillingDelivery[]> => {
      const {data} = (await axios.get(`/api/finance/billing/get-billingDeliveries-by-billing?billingId=${billingId}&status=${status}`));
      return await data;
    }
      
    const getBillingDeliveries = async (criteria: IBillingDeliverySearch, pagination?: IPagination) : Promise<IBillingDelivery[]> => {
      const {startDate, endDate, customerName, deliveryNumber, proforma, billingNumber} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/billingDelivery/get-billingDeliveries?customerName=${customerName}` +
        `&deliveryNumber=${deliveryNumber}&proforma=${proforma}&billingNumber=${billingNumber}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;

      
    }

    return {    
      createBillingForPayPal,
      updateBillingForPayPal,

      validateBillingForPayPal,

      getMyLastBillings,

      createBillingDelivery,
      updateBillingDelivery,

      deliverBillingDetail,

      getBilling,
      getBillings,

      getCommissionDistributionsByBilling,

      evaluateAddedProduct,
      
      getBillingsByRegistration,

      getDayDashboard,
      getMonthDashboard,
      getFinanceDashboard,

      getEmployeeBillingDashboard,

      getLastServicesByPerson,
      getBillingDeliveriesByBilling,
      getBillingDeliveries
    } 
}

export default _;

export interface IFilterBillingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IBilling[], React.Dispatch<React.SetStateAction<IBilling[]>>],
}

const defaultFilterProductOption: IFilterBillingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBilling = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBilling) => void,
                                            filterOption?: IFilterBillingOption  ) => {

  const { getBillings } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg  } = useRecoilValue(currentUserSessionAtom);

  //const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
   
    {id:lg.includes('en')?'customerFirstName':'customerLastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string'},
    {id:lg.includes('en')?'customerLastName':'customerFirstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string'},

    {id:'customerCorporationName', label : t('Name'),  display: true, type: 'string', },
    {id:'customerPortable1', label : t('Portable'),  display: true, type: 'string', },

    {id:'reference', label : t('Reference'),  display: true, type: 'string', },
    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },

    {id: 'status', label: t('Status'), display: true, type: 'expression', 
      getExpressionNode: (row: IBilling, cellId: keyof IBilling) => {
          switch(row.status) {
            case '95': return t('Not validated');
            case '85': return t('Not validated cancelled');
            case '10': return t('Validated');
            case '80': return t('Cancelled');
            default: return '....';
          } }},    
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', },
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'customerName', text: t('Customer'), value: ''}, 
    {name: 'customerPortable', text: t('Portable'), value: ''}, 

    {name: 'reference', text: t('Reference'), value: ''},     
      
    ]);    

  const [filteredBillings, ] = useState<IBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IBilling[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const customerName = filterElements.find( elt => elt.name === 'customerName')?.value || '';
    const customerPortable = filterElements.find( elt => elt.name === 'customerPortable')?.value || '';
   
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
          
    const arr = await getBillings( {startDate, endDate,customerName,customerPortable, reference}, pagination );
    
    return arr;
  }

  const objKey: keyof IBilling = 'id';

  return {
    title: t('Teller operation'), headCells: headBillingCells, objKey,
    filterElements, rows: filteredBillings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}



export interface IFilterBillingDeliveryOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IBillingDelivery[], React.Dispatch<React.SetStateAction<IBillingDelivery[]>>],
}

const defaultFilterBillingDeliveryOption: IFilterBillingDeliveryOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBillingDelivery = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBillingDelivery) => void,
                                            filterOption?: IFilterBillingDeliveryOption  ) => {

  const { getBillingDeliveries } = _();

  const { t, i18n } = useTranslation();   

  //const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterBillingDeliveryOption;



  const [headBillingDeliveryCells, setHeadBillingDeliveryCells]  = useState<HeadCell<IBillingDelivery>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'deliveryNumber', label : t('Delivery number'),  display: true, type: 'string', },
    
    {id:'firstName', label : t('Last name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
    {id:'corporationName', label : t('Name'),  display: true, type: 'string', },
    {id:'proformaNumber', label : t('Proforma'),  display: true, type: 'string', },
    {id:'billingNumber', label : t('Billing number'),  display: true, type: 'string', },

    // {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
        
    {id:'deliveryDate', label : t('Delivery date'),  display: true, type: 'date', },
    {id:'issueDate', label : t('Issue date'),  display: true, type: 'date', },
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'customerName', text: t('Customer'), value: ''}, 

    {name: 'deliveryNumber', text: t('Delivery number'), value: ''}, 
   
    {name: 'proforma', text: t('Proforma'), value: ''},     
    {name: 'billingNumber', text: t('Billing number'), value: ''},     
      
    ]);    

  const [filteredBillingDeliveries, ] = useState<IBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IBillingDelivery[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const customerName = filterElements.find( elt => elt.name === 'customerName')?.value || '';
    const deliveryNumber = filterElements.find( elt => elt.name === 'deliveryNumber')?.value || '';
   
    const proforma = filterElements.find( elt => elt.name === 'proforma')?.value || '';
    const billingNumber = filterElements.find( elt => elt.name === 'billingNumber')?.value || '';
          
    const arr = await getBillingDeliveries( {startDate, endDate,customerName,deliveryNumber, proforma,billingNumber}, pagination );
    
    return arr;
  }

  const objKey: keyof IBilling = 'id';

  return {
    title: t('Teller operation'), headCells: headBillingDeliveryCells, objKey,
    filterElements, rows: filteredBillingDeliveries, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}