import React, { useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IMainInformation, IHomeTop, defaultMainInformation, defaultHomeTop } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import { FormDialog } from 'components/ui/FormDialog';
import { AppointmentForm } from 'features/production/AppointmentForm';
import { defaultAppointment } from 'features/production/models/Appointment';
import { AppointmentFormDialog } from 'components/AppointmentFormDialog';
import { typographyBigGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';


const HomeTop = () => {

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getHomeTops } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: homeTops} = useQuery<IHomeTop[]>( ['HomeTop'], () => getHomeTops());

  const [openAppointmentForm, setOpenAppointmentForm] = useState<boolean>(false);

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

const [homeTop, setHomeTop] = useState<IHomeTop>(defaultHomeTop);
const [images, setImages] = useState<string[]>([]);
  useEffect(() => {
    
    if(!isFalsy(homeTops) && homeTops?.length>0) {
      const ht = homeTops[0];
      setHomeTop(homeTops[0]);

      setImages( [...new Set([ht.image_Url, ht.imageEx1_Url, ht.imageEx2_Url, ht.imageEx3_Url, ht.imageEx4_Url].filter(i => !isFalsy(i)))] )   
    }
      
}, [homeTops]);


  return (
    <Box bgcolor="white" color="back" py={0.25} px={0.25} textAlign="center">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={5}>            
            <Stack flexDirection='column'  textAlign="center"  alignItems="flex-start">
              
                
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h1' component="span"  
                    sx={{color:'#1AAF9D', fontWeight:'bold', fontFamily:'Poppins', fontSize: {xs: '2rem', md: '6rem' } }}>
                  {homeTop.titre} 
                </Typography>                
              </Box>         
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h3" {...typographySmallHandWriting}>
                  {homeTop.description} 
                </Typography>                
              </Box>
              
            </Stack>
          </Grid>    
              
          <Grid item xs={12} md={7}>
            <Carousel showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} 
                            infiniteLoop={true} autoPlay={true} interval={2000} stopOnHover={true} emulateTouch={true}
                            >
              {images.map((imageUrl, idx) => 
                  (
                    <div key={`${idx} : ${imageUrl}`} style={{ 
                      outline: 'none',
                      border: 'none',
                      width: '100%',
                      //height: '150px',
                      //borderRadius: '10%',
                      overflow: 'hidden',
                      margin: '0 auto'
                      } }>
                      <img src={`${globalConfig.get().apiUrl}/download/${imageUrl}`} alt="..." style={{ width: '100%', maxHeight: '450px', objectFit: 'cover', borderRadius: '2%'  }}/>
                    </div>
                  )
                )}
            </Carousel>
          </Grid>  
        </Grid>
    </Box>
  );
};


export default HomeTop;
