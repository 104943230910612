import React, { FC, useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  
     Card, CardActionArea, CardMedia, CardContent, Rating,
     GridSize, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';

import useProduct from 'features/production/services/Product';

import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyDescription, typographyGroupBoxStyling, typographySmallHandWriting, typographySmallGroupBoxStyling } from 'themes/commonStyles';
import Slider from 'react-slick';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';
import { IProduct } from './models/Product';
import { defaultCartProduct } from 'features/finance/models/Cart';


export interface ProductFilterFormProps {
  lineOfBusinessCode: string
}

export const ProductFilterForm: FC<ProductFilterFormProps> = (props: ProductFilterFormProps = {lineOfBusinessCode: ''}) => {

  const {lineOfBusinessCode} = props;

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getBestsellersDefinitions, getBestsellers } = useMainInformation();

  const { getProducts } = useProduct();

  const { addToCart } = useCart();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: bestsellersDefinitions} = useQuery<IBestsellersDefintion[]>( ['BestsellersDefinition'], () => getBestsellersDefinitions());
  //const {data: bestsellers} = useQuery<IBestsellers[]>( ['Bestsellers'], () => getBestsellers());

  const {data: products} = useQuery<IProduct[]>( ['Products'], () => getProducts({name: '', description: ''}));

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

  const [bestsellersDefinition, setBestsellersDefinition] = useState<IBestsellersDefintion>(defaultBestsellersDefintion);
  useEffect(() => {
    
      if(!isFalsy(bestsellersDefinitions) && bestsellersDefinitions?.length>0)
        setBestsellersDefinition(bestsellersDefinitions[0]);
}, [bestsellersDefinitions]);

const [productGridSizeXS, setProductGridSizeXS] = useState<GridSize>(6);
const [productGridSizeSM, setProductGridSizeSM] = useState<GridSize>(4);
const [productGridSizeMD, setProductGridSizeMD] = useState<GridSize>(3);

const [productStackHeight, setProductStackHeight] = useState<number>(350);

const [productImageDivHeight, setProductImageDivHeight] = useState<number>(150);

const [displayProductFilterOption, setDisplayProductFilterOption] = useState<boolean>(false);

useEffect(() => {
    
  setProductGridSizeXS(lineOfBusinessCode === 'ROBE_WAX' ? 12 : 6);
  setProductGridSizeSM(lineOfBusinessCode === 'ROBE_WAX' ? 6 : 4);
  setProductGridSizeMD(lineOfBusinessCode === 'ROBE_WAX' ? 4 : 3);
  
}, [lineOfBusinessCode]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2} textAlign="center">
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {/* {bestsellersDefinition.titre} */}
                  {t("Dans l'univers du bien-être")}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              
            </Stack>
          </Grid> 
          {(products || []).filter(x => x.lineOfBusinessCode === lineOfBusinessCode).map( (product, idx) => 
                (                
                <Grid item xs={lineOfBusinessCode==='ROBE_WAX'?12:6} sm={lineOfBusinessCode==='ROBE_WAX'?6:4} md={lineOfBusinessCode==='ROBE_WAX'?4:3} key={`car ${idx} ${product.name}`}>                  
                  <Stack flexDirection='column' textAlign="center" 
                      sx={ { padding: 0.25, marging: 5, //borderTopLeftRadius: '15%', borderTopRightRadius: '15%',  
                        borderRadius: '5%',
                        background: '#ffffff',
                        backgroundImage: 'none',
                        color: 'black',
                        transition: 'background-image 0.3s ease',
                        height: `${lineOfBusinessCode==='ROBE_WAX'?'515':'350'}px`,   
                        width: '100%',  
                        border: `${lineOfBusinessCode==='ROBE_WAX'?0:2}px solid #1AAF9D`          
                        } }>
                    <Box sx={{ mt: 1, p:0.25, width: '100%', display: 'flex', justifyContent: 'center', }}>     
                      <Link to= {{
                          pathname: `/productDetail/${product.id}`
                        }}>                  
                      { (!isFalsy(product.otherImage_Url) && product.image_Url !==  product.otherImage_Url ) ?
                        <Carousel showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} 
                            infiniteLoop={true} autoPlay={true} interval={2000} stopOnHover={true} emulateTouch={true}
                            >
                              {[product.image_Url, product.otherImage_Url].filter( x => !isFalsy(x)).map((imageUrl, idx) => 
                                (
                                  <div key={`${idx} : ${imageUrl}`} style={{ 
                                    outline: 'none',
                                    border: 'none',
                                    width: '100%',
                                    height: `${lineOfBusinessCode==='ROBE_WAX'?'325':'150'}px`,
                                    borderRadius: '10%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                    } }>
                                    <img src={`${globalConfig.get().apiUrl}/download/${imageUrl}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                                  </div>
                                )
                              )}
                        </Carousel> :
                        <div style={{
                          outline: 'none',
                          border: 'none',
                          width: '100%',
                          height: `${lineOfBusinessCode==='ROBE_WAX'?'325':'150'}px`,
                          borderRadius: '10%',
                          overflow: 'hidden',
                          margin: '0 auto'
                          } }>
                          <img src={`${globalConfig.get().apiUrl}/download/${product.image_Url}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                        </div>
                      }
                      </Link>
                    </Box>
                    <Box sx={{ mt: 1, mb: 1, height: `${lineOfBusinessCode==='ROBE_WAX'?'20':'30'}px`,width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: '#1AAF9D'}}>
                        {product.name}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.25, height: `${lineOfBusinessCode==='ROBE_WAX'?'20':'30'}px`, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h3" {...typographyDescription}>
                        {product.descriptionReduite}
                      </Typography>
                    </Box>
                    {!isFalsy(product.informationAge) && <Box sx={{ mt: 0.25, height: `${lineOfBusinessCode==='ROBE_WAX'?'15':'10'}px`, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h3" {...typographySmallGroupBoxStyling}>
                        {product.informationAge}
                      </Typography>
                    </Box> }
                    <Box sx={{ mt: 0.25, height: '15px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Rating name="product-rating" value={product.rating} precision={0.5} readOnly />
                    </Box>
                    <Box sx={{ mt: 0.25, height: '15px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: '#1AAF9D'}}>
                          {product.price.toFixed(2)} €
                        </Typography>
                    </Box>
                    
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Button variant="contained" 
                            onClick={() => { addToCart(
                                    {...defaultCartProduct, contentId: product.id, productId: product.productId,  
                                      quantity: 1, articleOption: '',
                                      unityAmount: product.price, netAmount: product.price,
                                      productName: product.name, 
                                      productDescription: product.descriptionReduite, productImage_Url: product.image_Url,
                                      productWarningAddToCart: product.warningAjoutPannier,
                                      productFilterOption: product.filterOption}); }
                                              }
                          sx={{
                              background: mainInformation.couleurBoutonPanier, 
                              //backgroundImage: 'linear-gradient(to right, #F26666, #400C07)',
                              color: mainInformation.couleurTexteBoutonPanier, 
                              marginTop:'15px',fontFamily: 'Poppins !important', width:`${lineOfBusinessCode==='ROBE_WAX'?'60':'80'}%`, height:'45px', 
                              borderRadius: '30px' }} >
                        {mainInformation.texteBoutonPanier}
                      </Button>
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      
                    </Box>

                  </Stack>
                  
                </Grid>
                ) 
              )}   
          
          
        </Grid>
        
    </Box>
  );
};


