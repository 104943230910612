import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { FC , MouseEvent, useEffect} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { currentFormNameAtom, currentUserSessionAtom, hubConnectionIdAtom } from 'library/store';

import useVisibilityToggle from 'library/useVisibilityToggle';

import useAxios from 'library/axios';
import { IOnlineCustomer, IOnlineCustomerPasswordReset, IPasswordChange, IUserCredential, IUserSession, defaultOnlineCustomer, defaultPasswordChange } from './models/User';

import useIdentityUserService from './services/User';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';
import { useLocalStorage } from 'react-use';
import { IResult } from 'library/interface';
import { isFalsy } from 'utility-types';

export const PasswordResetForm = () => {

  const axios = useAxios();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const {changePassword, postCredential, 
      createOnlineCustomerPasswordReset, updateOnlineCustomerPasswordReset, resetOnlineCustomerPassword} = useIdentityUserService();
  const { show: showPassword, toggleVisibility: togglePasswordVisibility } = useVisibilityToggle();

  const { show: showValidationCode, toggleVisibility: toggleShowValidationCode } = useVisibilityToggle();

  const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);

  const [ storeTenant, setStoreTenant, removeStoreTenant] = useLocalStorage<string>('Tenant');

  const methods = useForm<IOnlineCustomer>({defaultValues: {...defaultOnlineCustomer, userName: currentUserSession.userName} });
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const queryClient = useQueryClient();

  
  const {isLoading: isLoadingPasswordReset, isError: isErrorPasswordReset, isSuccess: isSuccessPasswordReset,
    error: errorPasswordReset, mutate: mutatePasswordReset } = useMutation<IResult<IOnlineCustomerPasswordReset>,Error,IOnlineCustomerPasswordReset>(createOnlineCustomerPasswordReset, 
  {        
  onSuccess: (data: IResult<IOnlineCustomerPasswordReset>) => {
    enqueueSnackbar( t('_Operation_done'), { variant: 'success',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
    
    if(data.succeeded) {
      toggleShowValidationCode();
      setValue('passwordResetOnlineCustomerId', data.data.id);
      setValue('token', data.data.token);
      setValue('id', data.data.onlineCustomerId);
    }        
  },
  onError: (err: Error) => {        
    enqueueSnackbar( error?.message, { variant: 'error',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
  }
  });

  const {mutate : mutateUpdatePasswordReset } = useMutation<IResult<IOnlineCustomerPasswordReset>,Error,{id: number, language: string}>(updateOnlineCustomerPasswordReset, 
    {        
      onSuccess: (data: IResult<IOnlineCustomerPasswordReset>) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });           
        
        if(data.succeeded) {
          
          setValue('passwordResetOnlineCustomerId', data.data.id);
          setValue('token', data.data.token);    
          setValue('id', data.data.onlineCustomerId);        
        }   
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      }
    });


  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IOnlineCustomer>,Error,IOnlineCustomer>(resetOnlineCustomerPassword, 
    {        
      onSuccess: (data: IResult<IOnlineCustomer>) => {
        
        if(!data.succeeded) {
          enqueueSnackbar( data.messages.join( '\n'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
        
        setCurrentUserSession({...currentUserSession, isAuthenticated: true});
        //navigate('/cartConfirm');
        // reset(data);
        // queryClient.invalidateQueries(['BankPolicy',data.policyID]);
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      }
    });

    useEffect( () => {        
      setCurrentFormNameAtom(t('Change password'));
      
    }, []);     

    const savePasswordReset = async (event: MouseEvent<HTMLButtonElement>) => {  
         
      const data = getValues(); 
      if(data.userName.trim() === '' ) {
          enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
    
      if(isFalsy(data.password)) {
          enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          return;
      }
  
      mutatePasswordReset({...data, email: data.userName, onlineCustomerId:0 ,language: 'fr-FR' , userValidationCode: '', token: ''});
  }

  const updatePasswordReset = async (event: MouseEvent<HTMLButtonElement>) => {  
         
    const data = getValues(); 
    if(data.userName.trim() === '' ) {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }
  
    if(isFalsy(data.password)) {
        enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
    }  
    mutateUpdatePasswordReset({id: getValues().validationCodeOnlineCustomerId, language: 'fr-FR' });
  }

  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {  
         
    const data = getValues(); console.log(data);
    if(data.userName.trim() === '') {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }
  
    if(isFalsy(data.password)) {
        enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
    }
  
    mutate({...data, passwordMD5: md5(data.password)});
  }

  

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
          <Grid container rowSpacing={3} columnSpacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={6} >                        
              <Stack flexDirection='column' padding={0} >
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                    {t("Reinitialiser le mot de passe")}
                  </Typography>
                </Box>
                
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographySmallGroupBoxStyling}}>
                    {currentUserSession.userName}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'left' }} >                                       
                  <TextField  sx={{width:'calc(80% - 8px)'}} id="password" label={t('Nouveau mot de passe')} 
                    {...register('password')} type={showPassword ? 'text' : 'password'}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} /> 
                </Box>
                { !showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                        
                    <Button variant="contained" onClick={savePasswordReset}
                            sx={{                              
                                marginTop:'8px',fontFamily: 'Poppins !important', 
                                borderRadius: '5' }} >
                          Generer le code de validation
                    </Button>         
                </Box> }
                { showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                  <Typography variant="h6" sx={{...typographySmallGroupBoxStyling}}>
                    {t('Votre code est dans votre mail')}
                  </Typography>        
                </Box> }
                { showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'left' }} >                  
                  <TextField  sx={{width:'calc(60% - 8px)'}} id="userName" label={t('Code de validation')} 
                    {...register('userValidationCode')}
                    error={!!errors.userName}
                    helperText={errors.userName?.message}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                    <Button variant="contained" onClick={saveData}
                          sx={{                              
                              marginTop:'8px', marginRight: '16px' , fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        OK
                    </Button>
                    <Button variant="outlined" onClick={updatePasswordReset}
                          sx={{                              
                              marginTop:'8px',fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        Regenerer
                  </Button>                        
                </Box> }
              </Stack>                        
            </Grid>
              
          </Grid>
      </Box>
    </FormProvider> 
  )
}

