

export interface IProduct {
    id: number,

    productId: number,

    name: string,
    description: string,
    
    descriptionReduite: string,

    informationAge: string,
    warningAjoutPannier: string,

    filterOption: string,
    articleWrapping: string,

    ingredient: string,
    conseilUtilisation: string,

    lineOfBusinessCode: string,
    lineOfBusinessName: string,
    
    price: number,
    rating: number,

    image: string,
    image_Url: string,

    otherImage: string,
    otherImage_Url: string,
}


export const defaultProduct : IProduct = {
    id: 0,
    productId: 0,
    
    name: '',
    description: '',
    descriptionReduite: '',

    informationAge: '',
    warningAjoutPannier: '',

    filterOption: '',
    articleWrapping: '',

    ingredient: '',
    conseilUtilisation: '',

    lineOfBusinessCode: '',

    lineOfBusinessName: '',
    
    price: 0,
    rating: 0,

    image: '',
    image_Url: '',

    otherImage: '',
    otherImage_Url: ''
}

export interface IProductSearch {
    name: string,
    description: string,    
  }