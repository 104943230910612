
import Decimal from 'decimal.js';

export type PaymentMethodType = 'payPal' | 'visa' | 'applePay';

export interface ICart {
    id: number,

    billingId: number,

    issueDate: Date,

    firstName: string,
    lastName: string,
    portable: string,
    whatsAppNumber: string,
    email: string,

    usePromotionCode: boolean,
    promotionCode: string,

    netAmount: number,
    tax: number,

    expeditionFees: number,
    expeditionFeesTax: number,

    amount: number,

    paymentMethod: PaymentMethodType,
    isConditionAccepted: boolean,

    shippingAddressLine1: string,
    shippingAddressLine2: string,
    shippingAddressCity: string,
    shippingAddressState: string,
    shippingAddressPostalCode: string,
    shippingAddressCountryCode: string,
    shippingAddressFirstName: string,
    shippingAddressLastName: string,

    cartProducts: ICartProduct[]    
}

export interface ICartProduct {
    id: number,

    cartId: number,

    contentId: number,
    productId: number,

    quantity: number,
    unityAmount: number,
    articleOption: string,

    discount: number,
    loaded: number,

    pricePurpose: string,

    netAmount: number,

    productName: string,
    productDescription: string,
    productRating: number,
    productImage_Url: string,

    productWarningAddToCart: string,
    productFilterOption: string,
    
}

export const defaultCart : ICart = {
    
    id: 0,

    billingId: 0,

    issueDate: new Date(),

    firstName: '',
    lastName: '',
    portable: '',
    whatsAppNumber: '',
    email: '',

    usePromotionCode: false,
    promotionCode: '',

    netAmount: 0,
    tax: 0,

    expeditionFees: 0,
    expeditionFeesTax: 0,

    amount: 0,

    paymentMethod: 'payPal',
    isConditionAccepted: false,

    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingAddressCity: '',
    shippingAddressState: '',
    shippingAddressPostalCode: '',
    shippingAddressCountryCode: '',
    shippingAddressFirstName: '',
    shippingAddressLastName: '',

    cartProducts: []    
}

export const defaultCartProduct : ICartProduct = {
    
    id: 0,

    cartId: 0,

    contentId: 0,
    productId: 0,

    quantity: 0,
    unityAmount: 0,
    articleOption: '',

    discount: 0,
    loaded: 0,

    pricePurpose: '',

    netAmount: 0, 

    productName: '',
    productDescription: '',
    productRating: 0,
    productImage_Url: '',

    productWarningAddToCart: '',
    productFilterOption: ''
}

export interface ICartSearch {
    firstName: string, lastName: string
}

