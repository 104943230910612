import React, {useState,useRef, useEffect} from 'react';

import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { FaFileCode } from 'react-icons/fa';
import { MdSecurity } from 'react-icons/md';
import { FaLock } from 'react-icons/fa';

import { useNavigate, Link as LinkNavigate } from 'react-router-dom';


import {  useRecoilValue, useRecoilState } from 'recoil';

import {Language as LanguageIcon, TranslateOutlined as TranslateOutlinedIcon,  } from '@mui/icons-material';

import { BsGlobe } from 'react-icons/bs';
import { IoChevronUpOutline } from 'react-icons/io5';
import { FaFileExport } from 'react-icons/fa';
import { TbLayoutDashboard } from 'react-icons/tb';


import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Popper from '@mui/material/Popper';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import { flexBetweenCenter, fullWidthFlex, justifyCenter, typographyBigGroupBoxStyling, typographyGroupBoxStyling } from 'themes/commonStyles';

import { isExportBoxShowAtom, isDesktopPublishingBoxShowAtom ,currentUserSessionAtom } from 'library/store';
import { Divider, Grid, Link, TextField, Typography } from '@mui/material';

import { globalConfig } from 'config';

import useUtils from 'library/utils';

import { IGaleryPhoto, IMainInformation, IPaymentModeSecure, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { isFalsy } from 'utility-types';


export const FooterSecurity = () => {

  const { getMainInformations, getPaymentModeSecures } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: paymentModeSecures} = useQuery<IPaymentModeSecure[]>( ['PaymentModeSecure'], () => getPaymentModeSecures());

  const {capitalizeFirstLetter, range} = useUtils();

  const { t, i18n } = useTranslation(); 

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
    }, [mainInformations]);

    const [photos, setPhotos] = useState<IPaymentModeSecure[]>([]);
    useEffect(() => {
        console.log(paymentModeSecures);
        setPhotos(paymentModeSecures || []);
    }, [paymentModeSecures]);
  

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        borderTop: '1px solid #ddd',
        background: mainInformation.couleurBaseBottom
      }}
    >
      <Grid container rowSpacing={0.5} columnSpacing={0.1}>
        <Grid item xs={12} sm={6} md={6} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='row' sx={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',}}  >  
            <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
              <FaLock size={24} />
              <Button key="key-logo-button" sx={{pl:0}}>
                <Typography 
                  sx={{
                    ml: 1,
                    //color: (theme) => theme.palette.secondary.main,
                    color: 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                  component="h3"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  Paiement securisé
                </Typography>
              </Button>
            </Box>     
            
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='row' sx={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',}}  >  
            {/* <Box sx={{ m : 0.5, width: '48px', height: '32px', overflow: 'hidden',borderRadius: '5%', display: 'flex',
                alignItems: 'center', justifyContent: 'flex-end', }} key={`galeryPhoto : ${photo.logo_Url} - ${ix}`}> 
                <img src={`${globalConfig.get().apiUrl}/download/${photo.logo_Url}`} alt="..." key={` img ${photo.logo_Url}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />                                                    
            </Box>                   */}
            {  photos.map( (photo,ix) => {                                                    
              return (
                <Box sx={{ m : 0.5, width: '48px', height: '32px', overflow: 'hidden',borderRadius: '5%', display: 'flex',
                    alignItems: 'center', justifyContent: 'flex-end', }} key={`galeryPhoto : ${photo.logo_Url} - ${ix}`}> 
                    <img src={`${globalConfig.get().apiUrl}/download/${photo.logo_Url}`} alt="..." key={` img ${photo.logo_Url}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />                                                    
                </Box>)
            }) }           
            
          </Stack>
        </Grid>
        
      </Grid>
    </Box>
  )
}
