import React, { useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  
    Link, Card, CardActionArea, CardMedia, CardContent, Rating, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting, typographyTextStyling } from 'themes/commonStyles';
import Slider from 'react-slick';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';

export const AboutUsForm = () => {

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getBestsellersDefinitions, getBestsellers } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: bestsellersDefinitions} = useQuery<IBestsellersDefintion[]>( ['BestsellersDefinition'], () => getBestsellersDefinitions());
  const {data: bestsellers} = useQuery<IBestsellers[]>( ['Bestsellers'], () => getBestsellers());

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

  const [bestsellersDefinition, setBestsellersDefinition] = useState<IBestsellersDefintion>(defaultBestsellersDefintion);
  useEffect(() => {
    
      if(!isFalsy(bestsellersDefinitions) && bestsellersDefinitions?.length>0)
        setBestsellersDefinition(bestsellersDefinitions[0]);
}, [bestsellersDefinitions]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2}>
        <Grid container justifyContent="space-between" >
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {/* {bestsellersDefinition.titre} */}
                  {t("Kiddies - Care")}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              
            </Stack>
          </Grid> 
          <Grid item xs={12} md={6}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
              <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageApropos_Url}`} 
                    alt="..." style={{ height: '350px', objectFit: 'cover', borderRadius: '2%'}}/>                
              </Box>
              
            </Stack>            
          </Grid>
          <Grid item xs={12} md={6}>            
            <Stack flexDirection='column' sx={{
                  fontFamily: 'Robot",Helvetica,Arial,sans-serif',
                  '& *': {
                    fontFamily: 'Robot",Helvetica,Arial,sans-serif',
                  },
                }}>
              <div dangerouslySetInnerHTML={{ __html: mainInformation.texteApropos }} />             
            </Stack>            
          </Grid>   
          
           
            
        </Grid>
        
    </Box>
  );
};


