import React, { useEffect, useState } from 'react';

import Box  from '@mui/material/Box';

import MenuIcon from '@mui/icons-material/Menu';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import SpaIcon from '@mui/icons-material/Spa';
import BrushIcon from '@mui/icons-material/Brush';
import StorefrontIcon from '@mui/icons-material/Storefront';

import Typography from '@mui/material/Typography';


import {
  flexBetweenCenter,
  dFlex,
  displayOnDesktop,
  displayOnMobile,
  typographyGroupBoxStyling,
  typographySmallGroupBoxStyling,
} from 'themes/commonStyles';

import { AppBar,  Dialog,  Divider,  Drawer, Badge,
    IconButton,List,ListItem,ListItemIcon,TextField,Toolbar } from '@mui/material';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';



import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';

import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import EventSeatIcon from '@mui/icons-material/EventSeat';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import PersonIcon from '@mui/icons-material/Person';

import LinkIcon from '@mui/icons-material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';

import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import useProduct from 'features/production/services/Product';
import { IProduct, defaultProduct } from 'features/production/models/Product';

import { currentUserSessionAtom, cartAtom, currentUserSessionSetAuthentication } from 'library/store';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';



export const HeaderMenu = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  
  const cart = useRecoilValue(cartAtom);

  const { getMainInformations } = useMainInformation();

  const { getProducts } = useProduct();

  const {data: products} = useQuery<IProduct[]>( ['Products'], () => getProducts({name: '', description: ''}));
  
  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());

  
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const handleClickMenu = (event : any) => {    
    setAnchorMenuEl(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  }

  
  const filter = createFilterOptions<IProduct>();
  const [searchValue, setSearchValue] = React.useState<IProduct | null>(null);

  const [anchorSearchEl, setAnchorSearchEl] = useState(null);
  const openSearch = Boolean(anchorSearchEl);
  const handleClickSearch = (event : any) => {    
    
    setAnchorSearchEl(event.currentTarget);
  }

  const handleCloseSearch = () => {    
    setAnchorSearchEl(null);
  }


  const [anchorSessionMenuEl, setAnchorSessionMenuEl] = useState(null);
  const openSessionMenu = Boolean(anchorSessionMenuEl);

  
  const handleClickSessionMenu = (event : any) => {    
    setAnchorSessionMenuEl(event.currentTarget);
  }

  const handleCloseSessionMenu = () => {
    setAnchorSessionMenuEl(null);
  }

  const menuItemClickDisconnect = (event : any) => {
    
    setCurrentUserSession({...currentUserSession, isAuthenticated: false ,isAuthenticationInitiatedByUser: true});
    navigate('/');        
  }

  const menuItemClickPasswordChange = (event: any) => {
    navigate('/passwordChange');  
  }


  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
  }, [mainInformations]);


  const [quantity, setQuantity] = useState<number>(0);
  useEffect(() => {
    
      setQuantity( cart.cartProducts.reduce((acc, product) => acc + product.quantity, 0) );
  }, [cart, cart.cartProducts]);

  

    return (
      <AppBar position="static" sx={{ backgroundColor: 'white' }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent={"space-around"}>
          <Grid item xs={4} container justifyContent="flex-start" sx={ {display: { xs: 'flex', md: 'none' }} }>
            <IconButton onClick={handleClickMenu} edge="start" sx={{ color: '#000', }} aria-label="menu">
              <MenuIcon sx={{ color: '#000', fontSize: { xs: '20px', md: '35px' } }} />
            </IconButton>
            <Menu anchorEl={anchorMenuEl}
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              > 
                <MenuItem onClick={() => {navigate('/')}}>
                  <ListItemIcon color="primary">
                    <HomeIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Typography {...typographySmallGroupBoxStyling} color="black">{t('Acceuil').toUpperCase()}</Typography>
                </MenuItem>      
                <Divider /> 
                <MenuItem onClick={() => {navigate('/productGamme')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuGamme_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">{t('Gammes').toUpperCase()}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {navigate('productProduit')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuProduit_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">{t('Produits capillaires et corporels').toUpperCase()}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {navigate('/productAccessoire')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuAccessoires_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">{t('Accessoires cheveux').toUpperCase()}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {navigate('productClothes')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuRobesWax_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">
                    {t('Robes africaines wax').toUpperCase()}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {navigate('aboutUs')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuApropos_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">
                    {t('About us').toUpperCase()}
                  </Typography>
                </MenuItem>    
                <Divider />
                <MenuItem onClick={() => {navigate('contact')}}>
                  {/* <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.imageMenuNousContacter_Url}`} alt="Home" style={{ width: 24, height: 24, marginRight: 8 }} /> */}
                  <Typography {...typographySmallGroupBoxStyling} color="black">{t('Nous contacter').toUpperCase()}</Typography>
                </MenuItem>
              </Menu>
            { !openSearch && <IconButton onClick={handleClickSearch} edge="start" sx={{ color: '#000', ml: '8px' }} aria-label="menu">
              <SearchIcon sx={{ color: '#000', fontSize: { xs: '20px', md: '35px' } }} />
            </IconButton> }
            { openSearch && <IconButton onClick={handleCloseSearch} edge="start" sx={{ color: '#000', ml: '8px' }} aria-label="menu">
              <CloseIcon sx={{ color: '#000', fontSize: { xs: '20px', md: '35px' } }} />
            </IconButton> }
              <Dialog //anchorEl={anchorSearchEl}
                open={openSearch}
                onClose={handleCloseSearch}
                //onClick={handleCloseSearch}
                maxWidth='sm'
              >
                <Autocomplete
                  value={searchValue}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setSearchValue({...defaultProduct,
                        name: newValue,
                      });
                    } else if (newValue && newValue.name) {
                      // Create a new value from the user input
                      setSearchValue({...defaultProduct,
                        name: newValue.name,
                      });
                    } else {
                      setSearchValue(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({...defaultProduct,
                        
                        name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={products || []}
                  getOptionLabel={(product) => {
                    // Value selected with enter, right from the input
                    // if (typeof option === 'string') {
                    //   return option;
                    // }
                    // // Add "xxx" option created dynamically
                    // if (option.inputValue) {
                    //   return option.inputValue;
                    // }
                    // Regular option
                    return product.name;
                  }}
                  renderOption={(props, product) => (
                    <li {...props}>
                      <div>
                        <img src={`${globalConfig.get().apiUrl}/download/${product.image_Url}`} alt={product.name} style={{ width: 24, height: 24, marginRight: 8 }} />
                      </div>
                      <span>{product.name}</span>
                    </li>
                  )}
                  sx={{ width: '350px' }}
                  
                  renderInput={(params) => (
                    <TextField {...params} label=""
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <SearchIcon sx={{ color: 'action.active', ml: 1, mr: 0.5 }} />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        sx: {
                          borderRadius: '20px', // Round border
                          backgroundColor: 'background.paper', // Background color
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Remove outline border
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Remove outline border on hover
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Remove outline border when focused
                          },
                          '& .MuiInputBase-input': {
                            padding: '10px 10px 10px 0', // Adjust input padding
                          },
                          //...params.InputProps.sx,
                        },
                      }} />
                    )}
                />
              </Dialog>
          </Grid>
          <Grid item xs={4} md={2}>            
              <Link to="/">
                <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} 
                alt="Logo" style={{ height: '96px', objectFit: 'cover', marginRight: '10px', }} />
              </Link>
          </Grid>
          <Grid item xs={10} container justifyContent="space-around" sx={ {display: { xs: 'none', md: 'flex' }} }>
            <Button color="inherit" component={Link} to="/productGamme" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('Gammes').toUpperCase()}</Button>
            <Button color="inherit" component={Link} to="/productProduit" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('Produits').toUpperCase()}</Button>
            <Button color="inherit" component={Link} to="/productAccessoire" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('Accessoires').toUpperCase()}</Button>
            <Button color="inherit" component={Link} to="/productClothes" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('Robes africaines wax').toUpperCase()}</Button>
            <Button color="inherit" component={Link} to="/aboutUs" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('About us').toUpperCase()}</Button>
            <Button color="inherit" component={Link} to="/contact" sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.9rem', '&:hover': { color: 'primary.main' } }}>{t('Nous contacter').toUpperCase()}</Button>

            <IconButton>
              <Link to="/cart">
                {quantity>0 ? 
                  <Badge badgeContent={quantity} sx={{ '& .MuiBadge-badge': {  backgroundColor: mainInformation.couleurBadgePanier, color: mainInformation.couleurTexteBadgePanier, fontWeight: 'bold' } }}>
                    <ShoppingCartIcon sx={{ fontSize: { xs: '25px', md: '35px' } , color:'#391510' }} />
                  </Badge> : 
                    <ShoppingCartIcon sx={{ fontSize: { xs: '25px', md: '35px' } , color:'#391510' }} />} 
              </Link>
            </IconButton>

            { !currentUserSession.isAuthenticated && <IconButton >
              <Link to="/authentication">
                <AccountCircleIcon sx={{ fontSize: { xs: '25px', md: '35px' }, color:'#391510'  }} />
              </Link>
            </IconButton> }

            { currentUserSession.isAuthenticated && <IconButton onClick={handleClickSessionMenu}>              
                <VerifiedUserIcon sx={{ fontSize: { xs: '25px', md: '35px' }, color:'#391510'  }} />              
            </IconButton> }
            <Menu anchorEl={anchorSessionMenuEl}
              open={openSessionMenu}
              onClose={handleCloseSessionMenu}
              onClick={handleCloseSessionMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              
              <MenuItem onClick={menuItemClickPasswordChange}>
                <ListItemIcon color="primary">
                  <ManageAccountsIcon fontSize="small" color="primary" />
                </ListItemIcon>
                {t('Change Password')}
              </MenuItem>
              <Divider />        
              <MenuItem onClick={() => {navigate('/myBillings')}}>
                <ListItemIcon color="primary">
                  <ShoppingCartIcon fontSize="small" color="primary" />
                </ListItemIcon>
                {t('Mes achats')}
              </MenuItem>
              <Divider />
              
              <MenuItem onClick={menuItemClickDisconnect}>
                <ListItemIcon color="primary">
                  <LogoutIcon fontSize="small" color="primary"/>
                </ListItemIcon>
                {t('Logout')}
              </MenuItem>
            </Menu>
            
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end" sx={ {display: { xs: 'flex', md: 'none' }} }>
            
            <IconButton>
              <Link to="/cart">
                {quantity>0 ? 
                  <Badge badgeContent={quantity} sx={{ '& .MuiBadge-badge': {  backgroundColor: mainInformation.couleurBadgePanier, color: mainInformation.couleurTexteBadgePanier, fontWeight: 'bold' } }}>
                    <ShoppingCartIcon sx={{ fontSize: { xs: '25px', md: '35px' } , color:'#391510' }} />
                  </Badge> : 
                    <ShoppingCartIcon sx={{ fontSize: { xs: '25px', md: '35px' } , color:'#391510' }} />} 
              </Link>
            </IconButton>
            
            { !currentUserSession.isAuthenticated && <IconButton >
              <Link to="/authentication">
                <AccountCircleIcon sx={{ fontSize: { xs: '25px', md: '35px' }, color:'#391510'  }} />
              </Link>
            </IconButton> }

            { currentUserSession.isAuthenticated && <IconButton onClick={handleClickSessionMenu}>              
                <VerifiedUserIcon sx={{ fontSize: { xs: '25px', md: '35px' }, color:'#391510'  }} />              
            </IconButton> }
            <Menu anchorEl={anchorSessionMenuEl}
              open={openSessionMenu}
              onClose={handleCloseSessionMenu}
              onClick={handleCloseSessionMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              
              <MenuItem onClick={menuItemClickPasswordChange}>
                <ListItemIcon color="primary">
                  <ManageAccountsIcon fontSize="small" color="primary" />
                </ListItemIcon>
                {t('Change Password')}
              </MenuItem>
              <Divider />      

              <MenuItem onClick={() => {navigate('/myBillings')}}>
                <ListItemIcon color="primary">
                  <ShoppingCartIcon fontSize="small" color="primary" />
                </ListItemIcon>
                {t('Mes achats')}
              </MenuItem>
              <Divider />  
              
              <MenuItem onClick={menuItemClickDisconnect}>
                <ListItemIcon color="primary">
                  <LogoutIcon fontSize="small" color="primary"/>
                </ListItemIcon>
                {t('Logout')}
              </MenuItem>
            </Menu>
          </Grid>
          
        </Grid>
        
      </Toolbar>
    </AppBar>
    );
  }
