
import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useTranslation  } from 'react-i18next';
import { useSnackbar } from 'notistack';

import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import BoltIcon from '@mui/icons-material/Bolt';

import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';

import ListItemIcon from '@mui/material/ListItemIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';

import { isArticleOptionDrowerOpenAtom,  currentEntityNameForActionDrawerAtom, currentEntityIdForActionDrawerAtom,
    currentUserSessionAtom, hubConnectionIdAtom, currentCartProduct2AddAtom,
    colorsAtom} from 'library/store';
import { globalConfig } from 'config';

import { justifyCenter, typographyGroupBoxStyling, carouselImage, typographyBigGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';

import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';

import entityService from 'features/services/Entity';
import BasicButtonList from './ui/BasicButtonList';
import { defaultFeatureDescription, IFeatureDescription, IFeatureParameter, IReportDescription } from 'library/interface';
import { isFalsy } from 'utility-types';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { FormDialog } from './ui/FormDialog';
import { Box, IconButton, Slide, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import ArrayField from './ui/ArrayField';

import { Watch, Triangle, RevolvingDot, Grid } from 'react-loader-spinner';


import {Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, IEnumerationItem}  from 'features/configuration/models/Enumeration';



export const ArticleOptionDrawer = () => {

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { getMainInformations, getEnumerationItemsByEnumerationCodes } = useMainInformation();
    const { addToCart } = useCart();

    const [Colors, setColors] = useRecoilState(colorsAtom);

    const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
    const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);

    const entityName = useRecoilValue(currentEntityNameForActionDrawerAtom);
    const entityId = useRecoilValue(currentEntityIdForActionDrawerAtom);

    const [openActionParams, setOpenActionParams] = useState(false);
    const [isArticleOptionDrowerOpen, setIsArticleOptionDrowerOpen] = useRecoilState(isArticleOptionDrowerOpenAtom);

    const [currentCartProduct2Add, setCurrentCartProduct2Add] = useRecoilState(currentCartProduct2AddAtom);
    //const { getParametersByFeature, generateEntityReport, getReportsByEntityForUser} = entityService();

    const [entityReports, setEntityReports] = useState<IReportDescription[]>([]);

    const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());

    const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
    useEffect(() => {
        
        if(!isFalsy(mainInformations) && mainInformations?.length>0)
            setMainInformation(mainInformations[0]);
    }, [mainInformations]);

    const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', Enum_ARTICLE_OPTION], () => getEnumerationItemsByEnumerationCodes( [Enum_ARTICLE_OPTION ] ));

    const methodsEntityAction = useForm<IReportDescription>( {defaultValues:defaultFeatureDescription} );
    const { register, setValue ,getValues ,watch, reset ,control , formState: { errors } } = methodsEntityAction;

    //const [isDirectReport, setIsDirectReport] = useState<boolean>(false);
    // let {fields: actionParams} = useFieldArray<IReportDescription,'params'>({
    // control, 
    // name: 'params'});

    // const {isLoading: isReportLoading, isError, isSuccess ,error,mutate: mutateEntity } = useMutation<{fileName: string, fileToken: string, isDirectReport: boolean, hubConnectionId: string},Error,IReportDescription>(
    //         generateEntityReport, {        
    //     onSuccess: (data: {fileName: string, fileToken: string, isDirectReport: boolean}) => {
          
    //       const {fileName, fileToken, isDirectReport} = data;
    //       if(isDirectReport) {
            
    //         setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
    //         setOpenPdf(true);
    //       }
    //       else
    //         enqueueSnackbar( t('_Operation_done'), { variant: 'success',
    //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });  
        
    //     },
    //     onError: (err: Error) => {
    //       enqueueSnackbar( error?.message, { variant: 'error',
    //             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
    //     }
    //   });
    
    const [selectedArticleOption, setSelectedArticleOption] = useState<string>('');
    const onClickProductFilterOption = (articleOption: string) => {
        setSelectedArticleOption(articleOption);        
    }

    const onClickAddToCart = async (event: MouseEvent<HTMLButtonElement>) => {
        if(isFalsy(selectedArticleOption))
            return;      
        
        addToCart(
            {...currentCartProduct2Add,
              articleOption: selectedArticleOption});
        setIsArticleOptionDrowerOpen(false)
    }

      const [urlPdf, setUrlPdf] = useState<string>('');
      const [openPdf, setOpenPdf] = useState<boolean>(false);

    useEffect( () => { 
        
        

        async function loadEntityReports() {                     
                  
            const entity = currentUserSession.roleEntities.find( e => e.entityName === entityName);
            
            if(!entity || entity == null || !entity.printAllowed ) {
                setEntityReports([]);
                return;
            }

           // console.log(isArticleOptionDrowerOpen);
          // const reports = await getReportsByEntityForUser(entityName,entityId);

          // setEntityReports(reports.filter( a => true ) );
        }

        
        loadEntityReports();
      }, [entityName,entityId,isArticleOptionDrowerOpen]);

    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
      
    }, [enumItems]);

  return (
      <Drawer open={isArticleOptionDrowerOpen} anchor="bottom"
            sx={{
                '& .MuiDrawer-paper': {
                width: '100%', // Full width
                height: 'auto', // Adjust height as needed
                //background: Colors.primary,
                color: Colors.secondary,
                borderRadius: '20px 20px 0px 0px',
                borderTop: `1px solid ${Colors.primary}`,
                },
            }}
            role="presentation"
      >
        <Stack flexDirection='column'>
        <Box
          sx={{
            position: 'relative', // Ensure positioning context
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2, // Padding for the close button container
          }}
        >
          <IconButton onClick={() => {setIsArticleOptionDrowerOpen(false);} }>
            <CloseIcon />
          </IconButton>
        </Box>
            <Box sx={{ mt: 1, width: '100%', display: 'flex'}} >
                <Stack flexDirection='row' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                    <Box sx={{ mt: 1, width: '40%', display: 'flex'}}>
                        <div style={{
                        outline: 'none',
                        border: 'none',
                        width: '100%',
                        height: '150px',
                        borderRadius: '10%',
                        overflow: 'hidden',
                        margin: '0 auto'
                        } }>
                            <img src={`${globalConfig.get().apiUrl}/download/${currentCartProduct2Add.productImage_Url}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                        </div>
                    </Box>
                    <Box sx={{ mt: 1, width: '60%', display: 'flex'}}>
                        <Stack flexDirection='column' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                            {currentCartProduct2Add.productName}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                            {currentCartProduct2Add.productDescription}
                            </Typography>
                        </Box>                        
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                {'TAILLE'}
                </Typography>
            </Box>            
            <Box key={` ${selectedArticleOption}`}  sx={{ mt: 1, width: '100%', display: 'flex'}} >
                { (refEnumItems.current || []).filter(x => x.enumerationCode === Enum_ARTICLE_OPTION && x.parentEnumerationItemCode === currentCartProduct2Add.productFilterOption).map((x, idx ) => (
                    <Button key={`idx ${idx} - ${x.code}`} variant="outlined" onClick={() => onClickProductFilterOption(x.code)}
                        sx={{                              
                            margin:'8px', fontFamily: 'Poppins !important', border: selectedArticleOption === x.code ? '5px solid' : '1px solid', 
                            borderRadius: '5px' }} >
                        {x.name}
                    </Button>))
                }
                
            </Box>
            <Box   sx={{ mt: 1, width: '100%', display: 'flex'}} >
                <Button variant="contained" onClick={onClickAddToCart}
                        sx={{                              
                            marginTop:'8px',fontFamily: 'Poppins !important', 
                            borderRadius: '5' }} >
                    {mainInformation.texteBoutonPanier}
                </Button>
            </Box>
        </Stack>
        
      </Drawer>
    
  )
}
