
export const Enum_ARTICLE_OPTION = 'Article_Option';
export const Enum_ARTICLE_OPTION_CLASS = 'Article_Option_Class';


export interface IEnumerationItem {
    id: number,
  
    enumerationId: number,
    enumerationCode: string,
    code: string,
    name: string,
    description: string,
  
    parentEnumerationItemCode: string,
    parentEnumerationCode: string,
        
    enumerationItemExs: IEnumerationItemEx[]
  }


  export interface IEnumerationItemEx {
    id: number,
    enumerationItemId: number,
  
    enumerationExId: number,
    enumerationExName: string,
    //enumerationExType: _ExtensionType,
  
    enumerationExOptions: {value: string, name: string} [],
  
    extensionValueId: number,
  
    value: any,
         
  
  }