//import { IUserContract } from "features/security/models/User"
// import { IApplicationSetup, defaultApplicationSetup } from "features/setup/models/ApplicationSetup"
//import { IRequestTypeBusinessApp } from "features/setup/models/RequestType"

import { IBilling } from "features/finance/models/Billing"


export interface IUserCredential {
    userName: string,
    password: string,
    
    language: string, 

    tenantAlias: string,
    hubConnectionId: string,  

    billing?: IBilling
  }


  export interface IUserSession {
    isAuthenticated: boolean,
    token: string,
    refreshToken: string,
    language: string,
        
    id: number,
    userName: string,
    userDescription: string,

    contractIds: number[],
    //userContracts: IUserContract[],

    shouldResetPassword: boolean,
    isPasswordComplexityAllowed: boolean,
    displayPasswordExpirationWarning: boolean,
    isExtractAndExportAllowed: boolean,
    
    currentFormName: string,

    currentEntityNameForAction: string,
    currentEntityIdForAction: number,

    isBackendOperation: boolean,

    isAuthenticationInitiatedByUser: boolean,

    roleEntities: {
        entityName: string,

        canCreate: boolean,
        canRetreive: boolean,
        canUpdate: boolean,
        printAllowed: boolean,
        worflowAllowed: boolean,
        attachAllowed: boolean,
        relativeViewAllowed: boolean,
        securityAllowed: boolean,
        linkAllowed: boolean,        
        
        caption: string,        
        icon: string,
        name: string,

        roleEntityFeatures: {
              id: number,
              roleEntityId: number,
              featureName: string,
              featureDescription: string,
            }[]
      } [],
    
      //applicationSetup: IApplicationSetup 
  }


  export const defaultUserSession : IUserSession = {
    isAuthenticated: false,
    token: '',
    refreshToken: '',
    language: 'fr-FR',

    id: 0,
    userName: '',
    userDescription: '',

    contractIds: [],
    //userContracts: [],

    shouldResetPassword: false,
    isPasswordComplexityAllowed: false,
    displayPasswordExpirationWarning: false,
    isExtractAndExportAllowed: false,

    currentFormName: '',

    isBackendOperation: false,


    isAuthenticationInitiatedByUser: true,
    
    roleEntities: [],

    currentEntityNameForAction: '',
    currentEntityIdForAction: 0,

    //applicationSetup: defaultApplicationSetup,
    
  }


  export interface IPasswordChange {
    userID: number,
    userName: string,
 
    password: string,
    newPassword: string,
    confirmNewPassword: string
  }

  export interface IOnlineCustomer {
    id: number,

    userName: string,
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    portable: string,
    email: string,

    passwordHash: string,
    salt: string,

    password: string,
    passwordMD5: string,

    validationCodeOnlineCustomerId: number,
    passwordResetOnlineCustomerId: number,

    userValidationCode: string,
    token: string,
  }
  

  export interface IOnlineCustomerValidationCode {
    id: number,
    onlineCustomerId: number,

    userName: string,
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    portable: string,
    email: string,

    language: string,

    userValidationCode: string,
    token: string
  }

  export interface IOnlineCustomerPasswordReset {
    id: number,
    onlineCustomerId: number,

    userName: string,
    email: string,

    language: string,

    userValidationCode: string,
    token: string
  }

  export const defaultPasswordChange: IPasswordChange = {
   userID: 0,
   userName: '',
 
   password: '',
   newPassword: '',
   confirmNewPassword: ''
  }

  export const defaultOnlineCustomer: IOnlineCustomer = {
    id: 0,

    userName: '',
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
    portable: '',
    email: '',

    passwordHash: '',
    salt: '',

    password: '',
    passwordMD5: '',

    validationCodeOnlineCustomerId: 0,
    passwordResetOnlineCustomerId: 0,

    userValidationCode: '',
    token: ''
   }