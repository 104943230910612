import React, { useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  
    Link, Card, CardActionArea, CardMedia, CardContent, Rating, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import Slider from 'react-slick';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';
import WhatsAppIcon  from '@mui/icons-material/WhatsApp';

export const ContactForm = () => {

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getBestsellersDefinitions, getBestsellers } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: bestsellersDefinitions} = useQuery<IBestsellersDefintion[]>( ['BestsellersDefinition'], () => getBestsellersDefinitions());
  const {data: bestsellers} = useQuery<IBestsellers[]>( ['Bestsellers'], () => getBestsellers());

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

  const [bestsellersDefinition, setBestsellersDefinition] = useState<IBestsellersDefintion>(defaultBestsellersDefintion);
  useEffect(() => {
    
      if(!isFalsy(bestsellersDefinitions) && bestsellersDefinitions?.length>0)
        setBestsellersDefinition(bestsellersDefinitions[0]);
}, [bestsellersDefinitions]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2} textAlign="center">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h3" {...typographySmallHandWriting}> 
                  {/* {bestsellersDefinition.titreGlobal} */}
                  {t('Bienvenue')}
                </Typography>                
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {/* {bestsellersDefinition.titre} */}
                  {t("Dans l'univers du bien-être")}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              
            </Stack>
          </Grid> 
          {/* <Grid item xs={12} sm={4}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h3" {...typographySmallHandWriting}> 
                 
                  {t('Appel et whatsap')}
                </Typography>                
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                 
                  {t('+33 6 20 14 58')}
                </Typography>
              </Box>
            </Stack>            
          </Grid>    */}
          <Grid item xs={12} sm={6}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h3" {...typographySmallHandWriting}> 
                  {/* {bestsellersDefinition.titreGlobal} */}
                  {t('NOUS SUIVRE')}
                </Typography>                
              </Box>
              <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center' }} > 
                <Typography >
                  <Link href={mainInformation.lienFacebook} target="_blank" sx={{fontFamily:'Poppins', textDecoration:'none', color:'#3b5998', marginRight:'15px'}}>
                      <FacebookIcon  sx={{fontSize:'40px'}}/>
                  </Link>  
                  <Link href={mainInformation.lienInstagram} target="_blank" sx={{fontFamily:'Poppins', textDecoration:'none', color:'#c13584', fontSize:'30px', marginRight:'15px'}}>
                      <InstagramIcon sx={{fontSize:'40px'}}/>
                  </Link>  
                  <Link href={mainInformation.lienWhatsApp} target="_blank" sx={{fontFamily:'Poppins', textDecoration:'none', color:'#25D366', fontSize:'30px', marginRight:'15px'}}>
                      <WhatsAppIcon sx={{fontSize:'40px'}}/>
                  </Link>
                  {/* <Link href={mainInformation.lienTwitter} target="_blank" sx={{fontFamily:'Poppins', textDecoration:'none', color:'#1da1f2', marginRight:'15px'}}>
                    <TwitterIcon sx={{fontSize:'40px', color:'#AE'}}/>
                  </Link>
                  <Link href={mainInformation.lienLinkedIn} target="_blank" sx={{fontFamily:'Poppins', textDecoration:'none', color:'#0a66c2'}}>
                    <LinkedInIcon sx={{fontSize:'40px', color:'#AE'}}/>
                  </Link> */}
                </Typography>
              </Box>
            </Stack>
          </Grid>  
          <Grid item xs={12} sm={6}>            
                <Stack flexDirection='column' textAlign="center" >
                    <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="h3" {...typographySmallHandWriting}> 
                        {/* {bestsellersDefinition.titreGlobal} */}
                        {t('Email')}
                        </Typography>                
                    </Box>
                    <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                        {/* {bestsellersDefinition.titre} */}
                        {mainInformation.email1}
                        </Typography>
                    </Box>
                </Stack>    
            </Grid>  
        </Grid>
        
    </Box>
  );
};


