import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { FC , MouseEvent, useEffect} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { currentFormNameAtom, currentUserSessionAtom } from 'library/store';

import useUtils from 'library/utils';

import { IPasswordChange, defaultPasswordChange } from './models/User';

import useIdentityUserService from './services/User';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { typographyBigGroupBoxStyling } from 'themes/commonStyles';

export const AuthenticationForm = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {isValidEmail} = useUtils();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const {changePassword, checkOnlineCustomer} = useIdentityUserService();

  const methods = useForm<{userName: string}>({defaultValues: {userName: currentUserSession.userName} });
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchUserName = watch('userName');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IPasswordChange,Error,IPasswordChange>(changePassword, 
    {        
      onSuccess: (data: IPasswordChange) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
        // reset(data);
        // queryClient.invalidateQueries(['BankPolicy',data.policyID]);
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      }
    });


    const onClickAuthenticationOK = async (event: MouseEvent<HTMLButtonElement>) => {        
      
      if( !isValidEmail(getValues().userName)) {
        enqueueSnackbar( t("Email n'est pas valide"), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
      }

      const checkCustomerObj = await checkOnlineCustomer(getValues().userName);
      setCurrentUserSession({...currentUserSession, userName: getValues().userName});
      const authNavigation = checkCustomerObj.exists ? 'login' : 'registration';
      navigate(`/${authNavigation}`);
  }

    useEffect( () => {        
      setCurrentFormNameAtom(t('Change password'));
      
    }, []);     

  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
    const data = getValues(); 
    if(data.userName.trim() === '') {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }
  
    // if(data.newPassword !== data.confirmNewPassword) {
    //     enqueueSnackbar( t('Password and confirmation are not the same'), { variant: 'error',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
    //     return;
    // }

    // mutate({...data,password: md5(data.password), newPassword: md5(data.newPassword), confirmNewPassword: md5(data.newPassword) });
}

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
          <Grid container rowSpacing={3} columnSpacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}  >                        
              <Stack flexDirection='column' padding={0} >
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                    {t('Se connecter / s’inscrire')}
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  
                    Renseignez votre adresse email pour vous connecter ou créer un compte
                  
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, width: '100%' }} >                                                          
                  <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />                          
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="userName" label={t('Email')} 
                    {...register('userName', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t('Adresse email invalide'),
                        } }
                    )}
                    error={!!errors.userName}
                    helperText={errors.userName?.message}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                    <Button variant="contained" onClick={onClickAuthenticationOK}
                          sx={{                              
                              marginTop:'8px',fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        OK
                    </Button>
                </Box>                      
                
              </Stack>                        
            </Grid>
          </Grid>
      </Box>
    </FormProvider> 
  )
}

