
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LinearProgress from '@mui/material/LinearProgress';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

//import { ChromePicker, ColorResult } from 'react-color';


import entityService  from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom, cartAtom } from 'library/store';
import useCartService, { useBasicFilterCart } from './services/Cart';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme, CircularProgress } from '@mui/material';
//import NumberFormat from 'react-number-format';

import { ICart,  defaultCart } from './models/Cart';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

//import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';

// import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
//       Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
//import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage, typographyBigGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
//import { IArticleStock } from 'features/configuration/models/Delivery';

import { globalConfig } from 'config';
import { addMinutes } from 'date-fns';
import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import { IBilling, defaultBilling } from './models/Billing';


type ExtensionUsage = 'content-A' | 'content-U';

export const MyBillingForm: FC<IBilling> = (props: IBilling = defaultBilling) => {


  const [cart, setCart] = useRecoilState(cartAtom);
  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  
  const { getMainInformations, getServices } = useMainInformation();

  const { removeToCart } = useCart();

  //const {data: services} = useQuery<IService[]>( ['Service'], () => getServices());


  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );


  //const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData } = entityService();

  //const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createCart, updateCart } = useCartService();

  const {range} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCart = useBasicFilterCart( 
    (event: React.MouseEvent<unknown>, row: ICart) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

//   const [openEntityFilter, setOpenEntityFilter] = useState(false);
//   const basicFilterEntity = useBasicFilterEntity( 
//       (event: React.MouseEvent<unknown>, row: IEntity) => {
//           const {name, description} = row;

                                    
//           setOpenEntityFilter(false);
//       }
//   );

//   const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
//   const basicFilterEnumeration = useBasicFilterEnumeration( 
//       (event: React.MouseEvent<unknown>, row: IEnumeration) => {
//           const {id, name, description} = row;

//           // setValue('enumerationId', id);
//           // setValue('enumerationName', name);
                           
//           setOpenEnumerationFilter(false);
//       }
//   );


  

  const methods = useForm<IBilling>({defaultValues: {...defaultBilling}});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

 
  const normaliseProgressValue = (value: number, maxValue: number) =>  (maxValue===0 || maxValue<=value)?100: (value* 100) / (maxValue);

  const watchFirstName = watch('customerFirstName');
  const watchLastName = watch('customerLastName');
  
//   const watchName = watch('name');    
//   const watchDescription = watch('description');    
//   const watchType = watch('type');    
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICart>,Error,ICart>(
      _id>0?updateCart:createCart, {   
        onSuccess: (data: IResult<ICart>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Cart')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Cart',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IBilling>(['Billing', _id], () => retrieveEntity('Billing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );
 
  
    

const handleCartValidate = async (event: MouseEvent<HTMLButtonElement>) => {
  if(currentUserSession.isAuthenticated)
    navigate('/cartConfirm');
  else {
    setCurrentUserSession({...currentUserSession, isAuthenticationInitiatedByUser: false});
    navigate('/authentication');
  }  
 } 

const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
  }, [mainInformations]);

  


    // const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    // useEffect( () => {
      
    //   async function _() {   
    //     if(_data && _data.type === 'article' && _data.id > 0) {         
    //       const stocks = await getArticlePerStores( 0, _id);
    //       setArticleStocks(stocks);
    //     }
    //   }
    //   _();  
    // }, [_data] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('Cart'));
        setCurrentBasicTextFilterProps(basicFilterCart);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
          // setCurrentFormName(t('Billing'));        
          setCurrentFormNameAtom(_id>0?`${t('Billing')} - # ${_id} # -`: t('Billing') );
    
          if(_id > 0 && getValues().id !== _id) 
            retrieveData('Billing',_id, refetch);  
        }, [_id] );
    
      useEffect( () => {
          console.log(_data);
        if(_data && _data.id > 0) {      
          reset(_data);
        }
      }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultCart });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        
      }

     
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        //openEntityActionDrawer('Cart', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                  <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                    <Stack flexDirection='column'  >                      
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                          <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnSaveCart' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                          <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                            {cart.cartProducts.length > 0 && t('Kiddies Care')}
                            </Typography>
                          </Box>                                                                        
                      </Box>                      
                    </Stack>                        
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('PANIER')}
                        </Typography>
                      </Box>
                      {  getValues().billingDetails.map( (cartProduct,ix) => {                                           
                        return (
                        <Box sx={{ mt: 1, width: '100%', display: 'flex'}} key={` product ${cartProduct.id} ${ix} `}>
                          <Stack flexDirection='row' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Box sx={{ mt: 1, width: '40%', display: 'flex'}}>
                              <div style={{
                                outline: 'none',
                                border: 'none',
                                width: '100%',
                                height: '150px',
                                borderRadius: '10%',
                                overflow: 'hidden',
                                margin: '0 auto'
                                } }>
                                <img src={`data:image/png;base64,${cartProduct.productBase64Picture}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                              </div>
                            </Box>
                            <Box sx={{ mt: 1, width: '60%', display: 'flex'}}>
                              <Stack flexDirection='column' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                   {cartProduct.productName}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                  {cartProduct.productDescription}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                  
                                 <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                  {cartProduct.quantity}
                                  </Typography>
                                  
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                                   {cartProduct.netAmount.toFixed(2)} €
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>)
                      }) }
                    </Stack>
                  </Grid> 
                  <Grid item xs={12} sm={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                        {t('Recapitulatif')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                          {t('Sous total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().netAmount.toFixed(2)}  €
                        </Typography>
                      </Box>
                                            
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('Total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().amount.toFixed(2)}  €
                        </Typography>
                      </Box>
                      
                    </Stack>
                  </Grid>   
                  
                   
                                                          
                </Grid>
                
            </Box>
        </FormProvider> 
  )
}

