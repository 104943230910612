import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { FC , MouseEvent, useEffect} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { currentFormNameAtom, currentUserSessionAtom } from 'library/store';

import useVisibilityToggle from 'library/useVisibilityToggle';

import { IOnlineCustomer, IOnlineCustomerValidationCode, IPasswordChange, defaultOnlineCustomer, defaultPasswordChange } from './models/User';

import useIdentityUserService from './services/User';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { IResult } from 'library/interface';

export const RegistrationForm: FC<IOnlineCustomer> = (props: IOnlineCustomer = defaultOnlineCustomer) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const {changePassword, createOnlineCustomer, createOnlineCustomerValidationCode, updateOnlineCustomerValidationCode} = useIdentityUserService();
  const { show: showPassword, toggleVisibility: togglePasswordVisibility } = useVisibilityToggle();


  const { show: showValidationCode, toggleVisibility: toggleShowValidationCode } = useVisibilityToggle();

  const methods = useForm<IOnlineCustomer>({defaultValues: {...defaultOnlineCustomer, userName: currentUserSession.userName} });
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const queryClient = useQueryClient();
  const {isLoading: isLoadingValidationCode, isError: isErrorValidationCode, isSuccess: isSuccessValidationCode,
         error: errorValidationCode, mutate: mutateValidationCode } = useMutation<IResult<IOnlineCustomerValidationCode>,Error,IOnlineCustomerValidationCode>(createOnlineCustomerValidationCode, 
    {        
      onSuccess: (data: IResult<IOnlineCustomerValidationCode>) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
        
        if(data.succeeded) {
          toggleShowValidationCode();
          setValue('validationCodeOnlineCustomerId', data.data.id);
          setValue('token', data.data.token);
        }        
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      }
    });

    
    const {mutate : mutateUpdateValidationCode } = useMutation<IResult<IOnlineCustomer>,Error,{id: number, language: string}>(updateOnlineCustomerValidationCode, 
      {        
        onSuccess: (data: IResult<IOnlineCustomer>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });           
          
          if(data.succeeded) {            
            setValue('validationCodeOnlineCustomerId', data.data.id);
            setValue('token', data.data.token);            
          }   
        },
        onError: (err: Error) => {        
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

      const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IOnlineCustomer>,Error,IOnlineCustomer>(createOnlineCustomer, 
        {        
          onSuccess: (data: IResult<IOnlineCustomer>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });  
                  
            if(data.succeeded) {
              navigate('/cartConfirm')
            }               
          },
          onError: (err: Error) => {        
            enqueueSnackbar( error?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          }
        });

    

  const saveValidationCode = async (event: MouseEvent<HTMLButtonElement>) => {  
         
    const data = getValues(); console.log(data);
    if(data.userName.trim() === '' || (data.firstName.trim() === '' && data.lastName.trim() === '')) {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }
  
    if(isFalsy(data.password)) {
        enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
    }

    mutateValidationCode({...data, email: data.userName, onlineCustomerId:0, language: 'fr-FR' , userValidationCode: '', token: ''});
}

const updateValidationCode = async (event: MouseEvent<HTMLButtonElement>) => {  
         
  const data = getValues(); 
  if(data.userName.trim() === '' || (data.firstName.trim() === '' && data.lastName.trim() === '')) {
      enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

  if(isFalsy(data.password)) {
      enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      return;
  }

  mutateUpdateValidationCode({id: getValues().validationCodeOnlineCustomerId, language: 'fr-FR' });
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {  
         
  const data = getValues(); console.log(data);
  if(data.userName.trim() === '' || (data.firstName.trim() === '' && data.lastName.trim() === '')) {
      enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

  if(isFalsy(data.password)) {
      enqueueSnackbar( t('Password is not define !!!'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      return;
  }

  mutate({...data, passwordMD5: md5(data.password) });
}


useEffect( () => {        
  setCurrentFormNameAtom(t('Change password'));
  
}, []);     

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
        <Grid container rowSpacing={3} columnSpacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={6} >
              <Stack flexDirection='column' padding={0} >
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                    {t("S'inscrire")}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, width: '100%' }} >                  
                  <Button variant="contained" onClick={() => {/*setOpenAppointmentForm(true);*/}}
                          sx={{                              
                              marginTop:'8px', marginRight: '16px' , fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        CONFIRMER
                  </Button>      
                  <Button variant="outlined" onClick={() => {navigate('/authentication')}}
                          sx={{                              
                              marginTop:'8px',fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        MODIFIER
                  </Button>         
                </Box> 
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographySmallGroupBoxStyling}}>
                    {currentUserSession.userName}
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                    {t("Informations personnelles")}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} >                                       
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="lastName" label={t('Nom')} {...register('lastName')}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} >                                       
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="firstName" label={t('Prenom')} {...register('firstName')}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} >                                       
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="portable" label={t('Portable')} {...register('portable')}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} >                                       
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="password" label={t('Mot de passe')} 
                    {...register('password')} type={showPassword ? 'text' : 'password'}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} /> 
                </Box>
                { !showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                        
                    <Button variant="contained" onClick={saveValidationCode}
                            sx={{                              
                                marginTop:'8px',fontFamily: 'Poppins !important', 
                                borderRadius: '5' }} >
                          CREER MON COMPTE
                    </Button>         
                </Box> }
                { showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                  <Typography variant="h6" sx={{...typographySmallGroupBoxStyling}}>
                    {t('Votre code est dans votre mail')}
                  </Typography>        
                </Box> } 
                { showValidationCode && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                  <TextField  sx={{width:'calc(60% - 8px)'}} id="userName" label={t('Code de validation')} 
                    {...register('userValidationCode')}
                    error={!!errors.userName}
                    helperText={errors.userName?.message}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                    <Button variant="contained" onClick={saveData}
                          sx={{                              
                              marginTop:'8px', marginRight: '16px' , fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        OK
                    </Button>
                    <Button variant="outlined" onClick={updateValidationCode}
                          sx={{                              
                              marginTop:'8px',fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        Regenerer
                  </Button>                        
                </Box> }
              </Stack>                        
            </Grid>
            
              
              
          </Grid>
      </Box>
    </FormProvider> 
  )
}

