import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';

import { useSnackbar } from 'notistack';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { sum } from 'lodash';

import useAxios from 'library/axios'; 

import {ICart, ICartProduct, ICartSearch, defaultCartProduct } from "../models/Cart";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { globalConfig } from 'config';

import { currentUserSessionAtom, cartAtom, isArticleOptionDrowerOpenAtom, currentCartProduct2AddAtom } from 'library/store';
import { isFalsy } from 'utility-types';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/appointmention/models/Policy';

import useMainInformation from 'features/setup/services/MainInformation';

import {Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, IEnumerationItem}  from 'features/configuration/models/Enumeration';


const _ = () => {

    const axios = useAxios(); 
    const { t, i18n } = useTranslation(); 
    const { enqueueSnackbar } = useSnackbar();

    const {getEnumerationItemsByEnumerationCodes } = useMainInformation();

    const [isArticleOptionDrowerOpen, setIsArticleOptionDrowerOpen] = useRecoilState(isArticleOptionDrowerOpenAtom);
    const [currentCartProduct2Add, setCurrentCartProduct2Add] = useRecoilState(currentCartProduct2AddAtom);

    const {data: enumItems} = useQuery<IEnumerationItem[]>(
      ['EnumerationItems', Enum_ARTICLE_OPTION], () => getEnumerationItemsByEnumerationCodes( [Enum_ARTICLE_OPTION ] ));

    const [cart, setCart] = useRecoilState(cartAtom);

    const createCart = async (appointment: ICart)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/public-api/appointment/v1/create`, appointment)).data;       
        
    const updateCart = async (appointment: ICart)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/public-api/appointment/v1/update`, appointment)).data; 
    
    const getCart = async (id  : number )  => {
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/public-api/appointment/v1/${id}`));
      return await data;
    }
   

    const getCarts = async (criteria: ICartSearch, pagination?: IPagination) : Promise<ICart[]> => {

      const { firstName, lastName } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/public-api/appointment/v1/get-appointments?&firstName=${firstName}&lastName=${lastName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const addToCart = async (cartProduct : ICartProduct )  => {
      
      const filterOptions = refEnumItems.current?.filter(en => en.enumerationCode === Enum_ARTICLE_OPTION 
        && en.parentEnumerationItemCode === cartProduct.productFilterOption); 

      if( (filterOptions?.length??0) === 1) {
        const option = filterOptions![0].code;
        cartProduct.articleOption = option;
      }
      
      if(cart.cartProducts.some(x => x.contentId === cartProduct.contentId && x.articleOption === cartProduct.articleOption ) ) {  
        return;
      }
      
      if( !isFalsy(cartProduct.productWarningAddToCart)) {
        enqueueSnackbar( cartProduct.productWarningAddToCart, { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3500 }); 
      }    
      
              
      if( (filterOptions?.length??0) > 1 && cartProduct.articleOption === '' ) {
        
        setCurrentCartProduct2Add(cartProduct);
        setIsArticleOptionDrowerOpen(true);        
      } else {

        const cartProducts = [...cart.cartProducts, {...cartProduct, quantity: 1}];
        const netAmount = sum( cartProducts.map(cartProduct => cartProduct.netAmount) );
        const amount = netAmount;

        setCart( {...cart,
          netAmount, amount,
          cartProducts} );
      }           
    }

    const removeToCart = async (contentId : number )  => {      
      
      const cartProducts = cart.cartProducts.filter(x => x.contentId !== contentId);
      const netAmount = sum( cartProducts.map(cartProduct => cartProduct.netAmount) );
      const amount = netAmount;

      setCart( {...cart,
        netAmount, amount,
        cartProducts} );      
    }

    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {           
        refEnumItems.current = enumItems;
      
    }, [enumItems])
     
    return {    
      createCart,
      updateCart,
      getCart,
      getCarts,

      addToCart,
      removeToCart
    } 
}

export default _;

export interface IFilterCartOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ICart[], React.Dispatch<React.SetStateAction<ICart[]>>],
}

const defaultFilterCartOption: IFilterCartOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterCart = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICart) => void,
                                            filterOption?: IFilterCartOption  ) => {

  const { getCarts } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterCartOption;



  const [headCartCells, setHeadCartCells]  = useState<HeadCell<ICart>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'firstName', label : t('Name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Description'),  display: true, type: 'string', },
    
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'firstName', text: t('Name'), value: ''},
      {name: 'lastName', text: t('Description'), value: ''},
      
    ]);    

  const [filteredCarts, ] = useState<ICart[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ICart[]> => {
   
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';
    
    
    const arr = await getCarts({ firstName, lastName}, pagination );
    
    return arr;
  }

  const objKey: keyof ICart = 'id';

  return {
    title: t('Cart'), headCells: headCartCells, objKey,
    filterElements, rows: filteredCarts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

