import React, { FC, useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  
     Card, CardActionArea, CardMedia, CardContent, Rating, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, ILongText, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';

import useProduct from 'features/production/services/Product';

import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyDescription, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import Slider from 'react-slick';



export const PrivacyPolicyForm = () => {

  
  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getLongTexts } = useMainInformation();

  const { getProducts } = useProduct();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: privacyPolicies} = useQuery<ILongText[]>( ['PrivacyPolicies'], () => getLongTexts('privacyPolicy'));


  
  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

  const [privacyPolicy, setPrivacyPolicy] = useState<ILongText>({id:0, texte: ''});
  useEffect(() => {
    
      if(!isFalsy(privacyPolicies) && privacyPolicies?.length>0)
        setPrivacyPolicy(privacyPolicies[0]);
}, [privacyPolicies]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2} >
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12}>            
            <Stack flexDirection='column' >
              
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {/* {bestsellersDefinition.titre} */}
                  {t("Dans l'univers du bien-être")}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              <div dangerouslySetInnerHTML={{ __html: privacyPolicy.texte }} />
            </Stack>
          </Grid> 
           
          
          
        </Grid>
        
    </Box>
  );
};


