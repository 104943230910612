import useAxios from 'library/axios'; 

import { IOnlineCustomer, IOnlineCustomerPasswordReset, IOnlineCustomerValidationCode, IPasswordChange, IUserCredential } from '../models/User';

import { globalConfig } from 'config';

const _ = () => {

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const sixCharsOrGreater = /.{6,}/g; // six characters or more

    const passwordTracker = (password: string) : 
            {uppercase: boolean, lowercase: boolean, number: boolean, specialChar: boolean, sixCharsOrGreater: boolean } => ({
        uppercase: password.match(atLeastOneUppercase) !== null,
        lowercase: password.match(atLeastOneLowercase) !== null,
        number: password.match(atLeastOneNumeric) !== null,
        specialChar: password.match(atLeastOneSpecialChar) !== null,
        sixCharsOrGreater: password.match(sixCharsOrGreater) !== null,
    })

    const passwordStrength = (password: string) : number => Object.values( passwordTracker(password) )
        .filter( (value) => value ).length

  const isPasswordStrong = (password: string) : boolean => passwordStrength(password) === 5

    const axios = useAxios();

    const changePassword = async (passwordChange: IPasswordChange) => 
        await (await axios.post('/api/identity/account/change-password', passwordChange)).data;


    const checkOnlineCustomer = async (userName: string): Promise<{exists: boolean}>  => {
        const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/check-online-customer?userName=${userName}`));
        return await data;
    }

    const createOnlineCustomerValidationCode = async (onlineCustomerValidationCode: IOnlineCustomerValidationCode) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/create-validation-code-public`, onlineCustomerValidationCode)).data;

    const updateOnlineCustomerValidationCode = async (data: {id: number, language: string}) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/update-validation-code-public`, data)).data;

    const createOnlineCustomer = async (onlineCustomer: IOnlineCustomer) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/create-public`, onlineCustomer)).data;

    const postCredential = async (md5Credential: IUserCredential) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/get-token`, {...md5Credential })).data;

    const createOnlineCustomerPasswordReset = async (onlineCustomerPasswordReset: IOnlineCustomerPasswordReset) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/create-password-reset-public`, onlineCustomerPasswordReset)).data;

    const updateOnlineCustomerPasswordReset = async (data: {id: number, language: string}) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/update-password-reset-public`, data)).data;

    const resetOnlineCustomerPassword = async (onlineCustomer: IOnlineCustomer) => 
        await (await axios.post(`${globalConfig.get().apiUrl}/api/security/onlineCustomer/v1/${globalConfig.get().applicationApiToken}/reset-password-public`, onlineCustomer)).data;

    const getDashboard = async ()  => {
        const {data} = (await axios.get('/crm/bankUser/getDashboard'));
        return await data;
    }

    return {
       
        changePassword,
        isPasswordStrong,
       
        getDashboard,
        checkOnlineCustomer,
        
        createOnlineCustomerValidationCode,
        updateOnlineCustomerValidationCode,
        createOnlineCustomer,

        createOnlineCustomerPasswordReset,
        updateOnlineCustomerPasswordReset,
        resetOnlineCustomerPassword,

        postCredential
    } 
}

export default _;