

import React from 'react';
import { Navigate, Outlet, Route, Routes} from 'react-router-dom';


import {  useRecoilValue, } from 'recoil';

///
import { currentUserSessionAtom } from 'library/store';

import { Login } from './Login';
//import { Home } from 'features/Home';

// import { ApplicationSetupForm, defaultApplicationSetup,
//     ApplicationQueryForm, defaultApplicationQuery, ContentTypeForm, defaultContentType } from 'features/setup';

// import { RoleForm, defaultRole,
//          UserForm, defaultUser } from 'features/security';

// import { ExternalLibraryForm, defaultExternalLibrary,
//          EnumerationForm, defaultEnumeration,
//          ReportForm, defaultReport,
//          ExtensionTypeForm, defaultExtensionType } from 'features/configuration';


// import { 
//     ContentForm,
//          MediaForm, defaultContent, defaultMedia,
         
//            } from 'features/production';

// import { JobForm, defaultJob,
//          SmsApiForm, defaultSmsApi } from 'features/misc';


import { AuthenticationForm, LoginForm, PasswordChangeForm, PasswordResetForm, RegistrationForm, defaultOnlineCustomer, defaultPasswordChange } from 'features/identity';
import Home from 'features/Home';
import { AboutUsForm, ContactForm, ProductDetailForm, ProductFilterForm } from 'features/production';
import { defaultProduct } from 'features/production/models/Product';
import { GeneralTermsAndConditionForm, LegalNoticeForm, PrivacyPolicyForm, ReturnPolicyForm } from 'features/setup';
import { CartConfirmForm, CartForm, MyBillingsForm, MyBillingForm, OperationApprovedForm, defaultBilling } from 'features/finance';

            

const ProtectedRoute = ( props: {redirectPath?: string} ) : React.ReactElement => {
    const {redirectPath} = props;

    const {isAuthenticated } = useRecoilValue(currentUserSessionAtom);
    
    return !isAuthenticated ? <Navigate to={redirectPath || '/login'} replace /> : <Outlet />;
}


export const AppRoute = () => {
    
    return (
    <Routes>
        <Route index element={<Home />} />

        <Route path="/" element={<Home />} />

        {/* <Route path="login" element={<Login />} /> */}

        <Route path="contact" element={<ContactForm />} />

        <Route path="aboutUs" element={<AboutUsForm />} />

        <Route path="productGamme" element={<ProductFilterForm {...{lineOfBusinessCode: 'GAMME'}} />} />
        <Route path="productProduit" element={<ProductFilterForm {...{lineOfBusinessCode: 'PRODUIT'}} />} />
        <Route path="productAccessoire" element={<ProductFilterForm {...{lineOfBusinessCode: 'ACCESSOIRES'}} />} />
        <Route path="productClothes" element={<ProductFilterForm {...{lineOfBusinessCode: 'ROBE_WAX'}} />} />


        <Route path="productDetail/:id" element={<ProductDetailForm {...defaultProduct} />} />

        <Route path="legalNotice" element={<LegalNoticeForm />} />
        <Route path="generalTermsAndCondition" element={<GeneralTermsAndConditionForm />} />
        <Route path="returnPolicy" element={<ReturnPolicyForm />} />
        <Route path="privacyPolicy" element={<PrivacyPolicyForm />} />


        <Route path="cart" element={<CartForm />} />
        <Route path="authentication" element={<AuthenticationForm />} />
        <Route path="registration" element={<RegistrationForm {...defaultOnlineCustomer} />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="resetPwd" element={<PasswordResetForm />} />


        <Route path="cartConfirm" element={<CartConfirmForm />} />

        <Route path="myBillings" element={<MyBillingsForm />} />
        <Route path="myBilling/:id" element={<MyBillingForm {...defaultBilling} />} />

        <Route path="operationApproved" element={<OperationApprovedForm />} />
        
        {/* <Route element={<ProtectedRoute />}>
          <Route path="home" element={<Home />} /> */} 

          {/* ------ Setup ----- */}
          {/* <Route path="applicationSetup/:id" element={<ApplicationSetupForm {...defaultApplicationSetup} />} />          
          <Route path="applicationQuery/:id" element={<ApplicationQueryForm {...defaultApplicationQuery} />} />

          
          <Route path="contentType/:id" element={<ContentTypeForm {...defaultContentType} />} /> */}

          
          {/* ------ Security ----- */}
          {/* <Route path="role/:id" element={<RoleForm {...defaultRole} />} />
          <Route path="user/:id" element={<UserForm {...defaultUser} />} /> */}

          {/* ------ Configuration ----- */}
          {/* <Route path="externalLibrary/:id" element={<ExternalLibraryForm {...defaultExternalLibrary} />} />
          <Route path="extensionType/:id" element={<ExtensionTypeForm {...defaultExtensionType} />} />
          <Route path="enumeration/:id" element={<EnumerationForm {...defaultEnumeration} />} />
          <Route path="report/:id" element={<ReportForm {...defaultReport} />} /> */}
        
          {/* ------ Production ----- */}
          {/* <Route path="media/:id" element={<MediaForm {...defaultMedia} />} />
          <Route path="content/:id" element={<ContentForm {...defaultContent} />} /> */}
          
          {/* ------ Misc ----- */}
                   

          {/* ------ Misc ----- */}
          {/* <Route path="job/:id" element={<JobForm {...defaultJob} />} />
          <Route path="smsApi/:id" element={<SmsApiForm {...defaultSmsApi} />} /> */}

          {/* ------ User profile ----- */}
          {/* <Route path="passwordChange" element={<PasswordChangeForm {...defaultPasswordChange} />} />
          
        </Route> */}
        
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
    );
    
}


