
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import {  useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LinearProgress from '@mui/material/LinearProgress';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//import { ChromePicker, ColorResult } from 'react-color';


//import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom, cartAtom } from 'library/store';
import useCartService, { useBasicFilterCart } from './services/Cart';

import useBillingService, { useBasicFilterBilling } from './services/Billing';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme, CircularProgress, Accordion, AccordionSummary, AccordionDetails, RadioGroup, Radio, Avatar, Link } from '@mui/material';
//import NumberFormat from 'react-number-format';

import { ICart,  PaymentMethodType,  defaultCart } from './models/Cart';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

//import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import useVisibilityToggle from 'library/useVisibilityToggle';

import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';

import {GeneralTermsAndConditionForm} from 'features/setup/GeneralTermsAndConditionForm'

// import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
//       Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { PayPalButtons, PayPalButtonsComponentProps , 
  PayPalCardFieldsForm, 
  PayPalCardFieldsProvider, 
  PayPalHostedField, PayPalHostedFieldsProvider, usePayPalCardFields, usePayPalHostedFields, usePayPalScriptReducer } from "@paypal/react-paypal-js";


import { IExtension, IExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
//import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage, typographyBigGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
//import { IArticleStock } from 'features/configuration/models/Delivery';

import { globalConfig } from 'config';
import { addMinutes } from 'date-fns';
import { IExpeditionMode, IMainInformation, defaultExpeditionMode, defaultMainInformation } from 'features/setup/models/MainInformation';
import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData } from '@paypal/paypal-js';

import useAxios from 'library/axios'; 

import { IBilling, IBillingDetail, IBillingShippingAddress, defaultBilling, defaultBillingDetail, defaultBillingShippingAddress } from './models/Billing';
import { ITellerOperationMode, defaultTellerOperationMode } from './models/TellerOperation';
import { IEnumerationItem } from 'features/configuration/models/Enumeration';
//import PayPalButton from 'components/ui/PayPalButton';



const SubmitPayment = ( props: {disabled: boolean} ) => {
  // Here declare the variable containing the hostedField instance
  //const hostedFields = usePayPalHostedFields();
  
  const {disabled} = props;
  const { cardFieldsForm, fields } = usePayPalCardFields();

const submitHandler = () => {  
  console.log('Here !!!');
  console.log(cardFieldsForm);
  console.log(typeof cardFieldsForm?.submit);


    if (typeof cardFieldsForm?.submit !== "function") return;
    cardFieldsForm
            .submit()
            .then(() => {
              console.log('submit successful');
                // submit successful
            })
            .catch(() => {
              console.log('submit error');
                // submission error
            });
  };

  return <Button onClick={submitHandler}
              variant="contained"
              disabled={disabled}
              sx={{
                backgroundColor: '#0070ba',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '4px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#005ea6',
                },
                width: '100%'
              }}
            >
              Payer par carte
            </Button>;
};


export const CartConfirmForm = () => {

  const axios = useAxios(); 

  const [cart, setCart] = useRecoilState(cartAtom);
  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  
  const { getMainInformations, getExpeditionModes, getEnumerationItemsByEnumerationCodes } = useMainInformation();

  const { removeToCart } = useCart();

  //const {data: services} = useQuery<IService[]>( ['Service'], () => getServices());


  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );


  //const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

//   const {retrieveEntity, retrieveData, openEntityActionDrawer, 
//       checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  //const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  // const { createCart, updateCart } = useCartService();

  const [openCardPaymentMethod, setOpenCardPaymentMethod] = useState<boolean>(false);
  const { createBillingForPayPal, updateBillingForPayPal, validateBillingForPayPal } = useBillingService();

  const {range} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);


  const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCart = useBasicFilterCart( 
    (event: React.MouseEvent<unknown>, row: ICart) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

//   const [openEntityFilter, setOpenEntityFilter] = useState(false);
//   const basicFilterEntity = useBasicFilterEntity( 
//       (event: React.MouseEvent<unknown>, row: IEntity) => {
//           const {name, description} = row;

                                    
//           setOpenEntityFilter(false);
//       }
//   );

//   const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
//   const basicFilterEnumeration = useBasicFilterEnumeration( 
//       (event: React.MouseEvent<unknown>, row: IEnumeration) => {
//           const {id, name, description} = row;

//           // setValue('enumerationId', id);
//           // setValue('enumerationName', name);
                           
//           setOpenEnumerationFilter(false);
//       }
//   );


  

  const methods = useForm<ICart>({defaultValues: {...cart, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

 
  const normaliseProgressValue = (value: number, maxValue: number) =>  (maxValue===0 || maxValue<=value)?100: (value* 100) / (maxValue);

  const watchIsConditionAccepted = watch('isConditionAccepted');
  const watchLastName = watch('lastName');

  const watchPaymentMethod = watch('paymentMethod');
  
//   const watchName = watch('name');    
//   const watchDescription = watch('description');    
//   const watchType = watch('type');    
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());  

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'CartConfirm'], () => getEnumerationItemsByEnumerationCodes
      ( ['Delivery_Country'] ));

  const queryClient = useQueryClient();
  const {isLoading: isLoadingBilling, isSuccess: isSuccessBilling, error: errorBilling, mutate: mutateBilling } = useMutation<IResult<IBilling>,Error,IBilling>(
      _id>0?updateBillingForPayPal:createBillingForPayPal, {   
        onSuccess: (data: IResult<IBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);

          _setId(data.data.id);

          setValue('billingId', data.data.id);
          setShowPaymentButton(true);
          //setCurrentEntityIdForAction(data.data.id);
          //setCurrentFormNameAtom(`${t('Cart')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Cart',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( errorBilling?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
          setShowPaymentButton(false);
        }
      });

    // const {data: _data, refetch} = useQuery<ICart>(['Cart', _id], () => retrieveEntity('Cart',_id), 
    //   {refetchOnWindowFocus: false ,enabled: false } );

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Cart'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
      //         Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
      //         Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ] ));
 
  const [currentExpeditionModeId, setCurrentExpeditionModeId] = useState<number>(0);
  const refCurrentExpeditionModeId = useRef<number>(0);

  const handleChangeExpeditionMode = (expeditionModeId: number) => {

      //console.log(expeditionModeId); console.log(refCurrentExpeditionModeId.current);
      setCurrentExpeditionModeId(expeditionModeId);
      refCurrentExpeditionModeId.current = expeditionModeId;
      const expeditionMode = (expeditionModes || []).find(x => x.id === expeditionModeId);
      if(isFalsy(expeditionMode)) return;

      setValue('expeditionFees', expeditionMode!.prix);
      setValue('expeditionFeesTax', 0);
      
      const {cartProducts} = cart;
      const netAmount = sum( cartProducts.map(cartProduct => cartProduct.netAmount) );

      const amount = netAmount + expeditionMode!.prix;
      setValue('amount', parseFloat(amount.toFixed(4)) );
      setValue('netAmount', parseFloat(amount.toFixed(4)) );
    };

    const handlePaymentMethod = ( event: React.MouseEvent<HTMLElement>, newPaymentMethodType: PaymentMethodType ) => {

      if(newPaymentMethodType === null) return;
      setValue('paymentMethod', newPaymentMethodType);

    };


    const handlePaymentSuccess = (details: any) => {
      console.log('Payment successful:', details);
      // Handle the successful payment here
    };
    
function openFileDialog() {
  (document as any).getElementById("file-upload").click();
}

const setFile = (_event: any) => {
  let f = _event.target.files![0];

  const fileSizeInKB = f.size / 1024;
  // Check if the file size is within your limit (e.g., 200 KB)
  if (fileSizeInKB > 200) {
    alert(t('File size should be less than 200 KB'));
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {

      var binaryString = reader.result as string;
  
      const base64String = binaryString
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');

    //   setValue("base64File", base64String);
    //   setValue("fileName", f.name);
    };

    reader.onerror = function () {
      console.log("File load failed");
    };    
    reader.readAsDataURL(f);    
};

const {data: expeditionModes} = useQuery<IExpeditionMode[]>( ['ExpeditionMode'], () => getExpeditionModes());

const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
const [openSalesCondition, setOpenSalesCondition] = useState<boolean>(false);

const [orderID, setOrderID] = useState<string>('');

// const [{ options }, dispatch] = usePayPalScriptReducer();

//     useEffect(() => {
//         dispatch({
//             type: 'resetOptions',
//             value: {
//                 ...options,
//                 'client-id': globalConfig.get().payPalClientId,
//                 'enable-funding': 'card',
//                 components: 'buttons,hosted-fields',
//             },
//         });
//     }, [dispatch, options]);

const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
  }, [mainInformations]);

  const refEnumItems = useRef<IEnumerationItem[]>([]);    
  useEffect( () => {   
      refEnumItems.current = enumItems || [];
    
  }, [enumItems]);

  
  useEffect(() => {
    reset(cart);
    //setQuantity( cart.cartProducts.reduce((acc, product) => acc + product.quantity, 0) );
  }, [cart, cart.cartProducts, reset]);

  useEffect(() => {
    const {cartProducts} = cart;
    const netAmount = sum( cartProducts.map(cartProduct => cartProduct.netAmount) );
    //    const amount = netAmount;
       
        setCart( {...cart,
          netAmount: parseFloat(netAmount.toFixed(4)), amount: parseFloat(netAmount.toFixed(4)),          
          cartProducts} );
  }, []);

  
      useEffect( () => {        
        setCurrentFormNameAtom(t('Cart'));
        setCurrentBasicTextFilterProps(basicFilterCart);
      }, []);    
    

      // Method to execute before the PayPal window is opened
      const executeBeforePayPalOpens = () => {
        // Add your code to execute here
        console.log("Executing before opening PayPal window...");

      };

      const onCreateOrder = async (data: CreateOrderData, actions: CreateOrderActions) => {
        return createOrder();      
      }

    const createOrder = async () : Promise<string> => {
      try {
             
        const data_ = getValues(); 

        //.log(data_);
        const billing2Save = {...defaultBilling, id: data_.billingId,
          onlineCustomerId: currentUserSession.id,
          
          netAmount: data_.netAmount,
          tax: data_.tax,
          amount: data_.amount,    
          amountPaid: 0, 
          unpaidAmount: data_.amount, 

          commissionAmount: 0,

          shippingAddressLine1: data_.shippingAddressLine1,
          shippingAddressLine2: data_.shippingAddressLine2,
          shippingAddressCity: data_.shippingAddressCity,
          shippingAddressState: data_.shippingAddressState,
          shippingAddressPostalCode: data_.shippingAddressPostalCode,
          shippingAddressCountryCode: data_.shippingAddressCountryCode,
          shippingAddressFirstName: data_.shippingAddressFirstName,
          shippingAddressLastName: data_.shippingAddressLastName,

          billingDetails: data_.cartProducts.map( x => ({...defaultBillingDetail,
            id: x.id, type: 'article', articleId : x.productId,  articleOption: x.articleOption,
            baseAmount: x.unityAmount, netAmount: x.netAmount, tax: 0, amount: x.netAmount,
            discount: 0, loaded: 0, pricePurpose: '', quantity: x.quantity
          } as IBillingDetail))
        };

        console.log(refCurrentExpeditionModeId.current); console.log(data_.expeditionFees); console.log(data_);
        if(data_.expeditionFees > 0 && refCurrentExpeditionModeId.current > 0) {            
          const expeditionMode = (expeditionModes || []).find(x => x.id === refCurrentExpeditionModeId.current);
          const {prix, productId} = expeditionMode!;
          console.log(expeditionMode);
          if(!isFalsy(expeditionMode)) {
            billing2Save.billingDetails.push({...defaultBillingDetail, type: 'fee', feeId: productId, employeeId: 0,
              baseAmount: prix, netAmount: prix, tax: 0, amount: prix,
              discount: 0, loaded: 0,  pricePurpose: '',                
              });
          }            
        }
    
        //console.log(billing2Save);
        const returnBillingSaved = (_id > 0 ? await updateBillingForPayPal(billing2Save) : await createBillingForPayPal(billing2Save) ) as IResult<IBilling>;

        _setId(returnBillingSaved.data.id);
        setValue('billingId', returnBillingSaved.data.id);      
      
        setOrderID(returnBillingSaved.data.reference);
        return returnBillingSaved.data.reference;
      } catch(error) {
        console.error(error);
        throw error;
      }
    }

    const onApproveOrder = async (data: OnApproveData, actions: OnApproveActions) => {
                  
      //console.log(details!.purchase_units![0].shipping?.address);
      console.log(refCurrentBillingShippingAddress.current);
      
      const {paymentID, payerID, subscriptionID, facilitatorAccessToken } = data;
      const tellerOperationMode = {...defaultTellerOperationMode, type: 'X', xPaymentID: paymentID,
        payerID,subscriptionID,facilitatorAccessToken, amount: getValues().amount} as ITellerOperationMode;

      return await approveOrder(tellerOperationMode);  
    }

    const approveOrder = async (tellerOperationMode: ITellerOperationMode) => {
      try {
        const billing = await validateBillingForPayPal({ id: getValues().billingId, 
          tellerOperationMode,
          billingShippingAddress: {...refCurrentBillingShippingAddress.current, id: 0, billingId: _id }
        });
        
      } catch(error) {
        console.error(error);
        throw error;
      }

      setCart({...defaultCart});
      navigate('/operationApproved');
      
      return Promise.resolve();
    }

    //const [currentBillingShippingAddress, setBillingCurrentShippingAddress] = useState<IBillingShippingAddress>({...defaultBillingShippingAddress});
    
    const refCurrentBillingShippingAddress = useRef<IBillingShippingAddress>({...defaultBillingShippingAddress});
    const onShippingAddressChange : PayPalButtonsComponentProps['onShippingAddressChange'] = (data, actions) => {
      //console.log(data);
      //if(data.shippingAddress.countryCode !== 'FR' ) {
        //return actions.reject();
      //}

      console.log(data);
      refCurrentBillingShippingAddress.current = {...data.shippingAddress} as IBillingShippingAddress;
      //setBillingCurrentShippingAddress({...data.shippingAddress} as IBillingShippingAddress);

      // Return a promise to satisfy the type definition
      return Promise.resolve();
    }


    const onShippingOptionsChange : PayPalButtonsComponentProps['onShippingOptionsChange'] = (data, actions) => {
      console.log(data);
      //if(data.shippingAddress.countryCode !== 'FR' ) {
        //return actions.reject();
      //}
      
      // Return a promise to satisfy the type definition
      return Promise.resolve();
    }

      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        // useEffect( () => {
        //     // setCurrentFormName(t('Billing'));        
        //     setCurrentFormNameAtom(_id>0?`${t('Cart')} - # ${_id} # -`: t('Cart') );
        //     if(_id > 0)
        //       retrieveData('Cart',_id, refetch);  
        //   }, [_id] );
    
    
        // useEffect( () => {
            
        // if(_data && _data.id > 0) {
        //     reset(_data);
        // }
        // }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultCart });    
      }
      
      const saveBilling = async (event: MouseEvent<HTMLButtonElement>) => {      
        // if(!checkEntitySaveAuthorization('Cart', _id)) {
        //   setIsSaveLoading(false);
        //      return;
        // }          
  
        const data = getValues(); 
                        
        

        if(currentUserSession.id <= 0 || !currentUserSession.isAuthenticated) {
            enqueueSnackbar( t('Validated user is'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          
      }

      const allowChangeShipping = async (event: MouseEvent<HTMLButtonElement>) => {
        setShowPaymentButton(false);
      }
      
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        //openEntityActionDrawer('Cart', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }


  return (
      
      <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1} justifyContent='center'>
                  <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                    <Stack flexDirection='column'  >                      
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                          {/* <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnSaveCart' onClick={saveData} sx={ {display:'none'}}  /> */}
                          <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                          <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                            {cart.cartProducts.length > 0 && t('Kiddies Care')}
                            </Typography>
                          </Box>                                                                        
                      </Box>                      
                    </Stack>                        
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h1" sx={{...typographyBigGroupBoxStyling}}>
                        {t('Avez vous un code promo ?')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(50% - 8px)'}} id="firstName" label={t('Code promo')} {...register('promotionCode')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                        <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                            label={`Utiliser le code ?`}
                            control={
                              <Controller
                                name={`usePromotionCode`}
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                        {t('Recapitulatif')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                          {t('Sous total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().netAmount.toFixed(2)}  €
                        </Typography>
                      </Box>
                      { (getValues().netAmount < mainInformation.montantMinPourLivraisonGratuite) && <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                          {t("Mode d'expedition")}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().expeditionFees.toFixed(2)}  €
                        </Typography>
                      </Box> }
                      { (getValues().netAmount < mainInformation.montantMinPourLivraisonGratuite) && <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%',
                             marginLeft: 3, marginRight: 2 }}>
                        <RadioGroup                         
                          value={refCurrentExpeditionModeId.current}
                          onChange={(e) => { handleChangeExpeditionMode(parseInt(e.target.value)); } }
                          sx={{ marginBottom: '20px' }}
                        >
                          { [...(expeditionModes || [] )].map((expeditionMode) => (
                            <Box key={expeditionMode.id} sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                              <FormControlLabel disabled={showPaymentButton}
                                key={expeditionMode.id}
                                value={expeditionMode.id}
                                control={<Radio sx={{p: '3px'}} />}
                                label={expeditionMode.nom}
                                sx={{p: '2px', mt: '2px'}}
                              />
                              <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                                {expeditionMode.prix.toFixed(2)}  €
                              </Typography>
                            </Box>
                          ))}
                          
                          
                        </RadioGroup>                                                
                      </Box> }
                      { (getValues().netAmount >= mainInformation.montantMinPourLivraisonGratuite) && <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%',
                             marginLeft: '20px', marginRight: '10px' }}>
                         <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'left'}}>
                          {"Vous beneficez d'une livraison gratuite."}
                        </Typography>                                               
                      </Box>
                      }
                      <Box sx={{ mt: 3, ml: 6,display: 'flex', flexDirection: 'column', width: '90%',
                             marginLeft: '20px', marginRight: '10px' }}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'left'}}>
                              {"LIVRAISON"}
                            </Typography>                                               
                          </Box>
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Controller 
                          name={`shippingAddressCountryCode`}
                            control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select  onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="shippingAddressCountryCode"
                                    label={`${t('Pays')} /  ${t('Region')}`} inputProps={ {readOnly: false}} >
                                    {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === 'Delivery_Country' ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />                            
                      </Box>
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(50% - 8px)'}} id="shippingAddressFirstName" label={`${t('First name')}`} {...register('shippingAddressFirstName')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                        <TextField sx={{width:'calc(50% - 8px)'}} id="shippingAddressFirstName" label={`${t('Last name')}`} {...register('shippingAddressFirstName')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box>
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(100% - 8px)'}} id="shippingAddressLine1" label={`${t('Address')}`} {...register('shippingAddressLine1')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box>
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(100% - 8px)'}} id="shippingAddressLine2" label={`${t('Appartement, suite, ...')}`} {...register('shippingAddressLine2')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box>
                      {/* <Box sx={{ mt: 0.25, ml: 3, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(100% - 8px)'}} id="shippingAddressState" label={`${t('State')}`} {...register('shippingAddressState')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box> */}
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6,width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(100% - 8px)'}} id="shippingAddressCity" label={`${t('Town')}`} {...register('shippingAddressCity')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box>
                      <Box sx={{ mt: 0.25, ml: 6, pr: 6,width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(100% - 8px)'}} id="shippingAddressPostalCode" label={`${t('Code postal')} `} {...register('shippingAddressPostalCode')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                      </Box>
                      
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('Total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().amount.toFixed(2)}  €
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                            label={
                              <Typography>
                                J'ai lu et j'accepte les {' '}
                                <Link href="#" rel="noopener" onClick={() => {setOpenSalesCondition(true);}}>
                                Conditions Générales de Vente
                                </Link>                                
                              </Typography>
                            }
                            control={
                              <Controller
                                name={`isConditionAccepted`}
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                      </Box>
                      { openSalesCondition && <FormDialog open={openSalesCondition} maxWidth='md'
                          okText='' cancelText='' title={t('Conditions de vente')} onCancel={()=> {}} 
                          onClose={()=> {setOpenSalesCondition(false);}} onOk={()=> {setOpenSalesCondition(false);}}  >
                              <GeneralTermsAndConditionForm />
                      </FormDialog> }
                      {/* <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >   
                        <Button variant="contained" onClick={saveBilling} disabled={showPaymentButton}
                            sx={{                              
                                marginTop:'8px',  marginRight: '16px' ,fontFamily: 'Poppins !important', 
                                borderRadius: '5', }} >
                          Valider votre option de livraison
                        </Button> 
                        <Button variant="contained" onClick={allowChangeShipping} disabled={!showPaymentButton}
                            sx={{                              
                                marginTop:'8px',  marginRight: '16px' ,fontFamily: 'Poppins !important', 
                                borderRadius: '5' }} >
                          Modifier l'option de livraison
                        </Button>
                      </Box> */}
                      {/* <Box sx={{ mt: 1, width: '100%' }} >
                        <ToggleButtonGroup value={watchPaymentMethod} exclusive key={watchPaymentMethod} size="small" onChange={handlePaymentMethod} aria-label="text alignment" fullWidth >
                          <ToggleButton value="payPal" aria-label="centered">
                          {t('Paypal')}                            
                          </ToggleButton> 
                          <ToggleButton value="visa" aria-label="centered">
                            {t('Visa')}
                          </ToggleButton>
                          <ToggleButton value="applePay" aria-label="centered">
                            {t('Apple pay')}
                          </ToggleButton>                          
                        </ToggleButtonGroup>                                                             
                      </Box> */}
                      { /*showPaymentButton &&*/ <Box sx={{ mt: 1, width: '100%' }} key={` paypal ${watchIsConditionAccepted} `}>
                      
                          <PayPalButtons 
                            key={` paypal ${watchIsConditionAccepted} `}
                            //style={{ layout: 'vertical' }}
                            style={{ layout: "horizontal" }}
                            
                            onShippingAddressChange={onShippingAddressChange}
                            onShippingOptionsChange={onShippingOptionsChange}
                         
                            onClick={executeBeforePayPalOpens}
                            
                            disabled={!watchIsConditionAccepted || currentExpeditionModeId<=0}
                            
                            createOrder={(data, actions) => onCreateOrder(data, actions)}
                            onApprove={(data, actions) => onApproveOrder(data, actions)}

                            // onCancel={(data, actions) => onCancelOrder(data, actions)}
                            // onError={(data) => on}
                          /> 
                          <Button onClick={() => {setOpenCardPaymentMethod(true);}}
                            variant="contained"
                            disabled={!watchIsConditionAccepted || currentExpeditionModeId<=0}
                            sx={{
                              backgroundColor: '#0070ba',
                              color: '#fff',
                              padding: '10px 20px',
                              borderRadius: '4px',
                              textTransform: 'none',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              '&:hover': {
                                backgroundColor: '#005ea6',
                              },
                              width: '100%'
                            }}
                          >
                            Payer par carte
                          </Button>

                      </Box> }  
                      { <FormDialog open={openCardPaymentMethod} maxWidth='sm' height='60vh'
                          okText={''} cancelText={''} title={`${t('Paiement par carte')}...`} onCancel={()=> {setOpenCardPaymentMethod(false);}} 
                          onClose={()=> {setOpenCardPaymentMethod(false);}} onOk={()=> {setOpenCardPaymentMethod(false);}}  >
                          <PayPalCardFieldsProvider
                            
                            createOrder={() =>{console.log('create order card'); return createOrder(); }}
                            onApprove={(data) => {console.log('approve order card'); 
                              return approveOrder({...defaultTellerOperationMode, type: 'T', amount: getValues().amount}) }}
                            onError={(err) => {}}
                          >
                              <PayPalCardFieldsForm  />
                              <SubmitPayment key={` card ${watchIsConditionAccepted} `} disabled={!watchIsConditionAccepted} />
                          </PayPalCardFieldsProvider>
                        </FormDialog>
                      }                    
                    </Stack>
                  </Grid>   
                  
                    
                                                          
                </Grid>
                
            </Box>
        </FormProvider> 
  )
}

