import { HeadCell } from 'components/ui/EnhancedTable';
//import useAxios from 'library/axios'; 
import { useState } from 'react';

import axios from 'axios';

import { useTranslation  } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IGaleryPhoto, IIMage4Carousel, IMainInformation, IHomeTop, ISlideImage, ISpecialOffer, ISpecialOfferDefintion, IAdvice, IAdviceDefinition, IValueDefintion, IValueKid, IBestsellersDefintion, IBestsellers, IExpeditionMode, ILongText, IPaymentModeSecure  } from "../models/MainInformation";

import { globalConfig } from 'config';
import { IService } from 'features/production/models/Appointment';
import { IEnumerationItem } from 'features/configuration/models/Enumeration';


const _ = () => {

    //const axios = useAxios();     

    // const createContact = async (contact: IContact)  =>       
    //     await (await axios.post('/api/production/contact/create', contact)).data;       
        
    // const updateContact = async (contact: IContact)  =>       
    //     await (await axios.post('/api/production/contact/update', contact)).data; 
    
    // const getContact = async (id  : number )  => {
    //   const {data} = (await axios.get(`/api/production/contact/get-contact/${id}`));
    //   return await data;
    // }

    const getMainInformations = async (pagination?: IPagination) : Promise<IMainInformation[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;

      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/main/get-contents`));
      return await data;
    }

    const getImages4Carousel = async (pagination?: IPagination) : Promise<IIMage4Carousel[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;

      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/carouselImage/get-contents`));
      return await data;
    }

    const getSlideImages = async (pagination?: IPagination) : Promise<ISlideImage[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/slidelImage/get-contents`));
      return await data;
    }


    const getValueDefinitions = async (pagination?: IPagination) : Promise<IValueDefintion[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/valueDef/get-contents`));
      return await data;
    }

    const getValueKids = async (pagination?: IPagination) : Promise<IValueKid[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/value/get-contents`));
      return await data;
    }

    const getSpecialOfferDefintions = async (pagination?: IPagination) : Promise<ISpecialOfferDefintion[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/offerDef/get-contents`));
      return await data;
    }

    const getSpecialOffers = async (pagination?: IPagination) : Promise<ISpecialOffer[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/offer/get-contents`));
      return await data;
    }

    const getAdviceDefinitions = async (pagination?: IPagination) : Promise<IAdviceDefinition[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/adviceDef/get-contents`));
      return await data;
    }

    const getAdvices = async (pagination?: IPagination) : Promise<IAdvice[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/advice/get-contents`));
      return await data;
    }

    const getHomeTops = async (pagination?: IPagination) : Promise<IHomeTop[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/homeTop/get-contents`));
      return await data;
    }

    const getBestsellersDefinitions = async (pagination?: IPagination) : Promise<IBestsellersDefintion[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/venteDef/get-contents`));
      return await data;
    }

    const getBestsellers = async (pagination?: IPagination) : Promise<IBestsellers[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/vente/get-contents`));
      return await data;
    }

    const getGaleryPhotos = async (pagination?: IPagination) : Promise<IGaleryPhoto[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/photoGalery/get-contents`));
      return await data;
    }

    const getServices = async (pagination?: IPagination) : Promise<IService[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/service/get-contents`));
      return await data;
    }

const getExpeditionModes = async (pagination?: IPagination) : Promise<IExpeditionMode[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/expeditionMode/get-contents`));
      return await data;
    }


    const getLongTexts = async (apiDef: string, pagination?: IPagination) : Promise<ILongText[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/${apiDef}/get-contents`));
      return await data;
    }

    const getPaymentModeSecures = async (pagination?: IPagination) : Promise<IPaymentModeSecure[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;
      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/paymentModeSecure/get-contents`));
      return await data;
    }
        
    const getEnumerationItemsByEnumerationCodes = async (enumerationCodes: string[]) : Promise<IEnumerationItem[]>  => {
      
      const codes = enumerationCodes.join(':-:');
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/public/configuration/enumeration/v1/${globalConfig.get().applicationApiToken}/get-enumerationItems-by-enumerationCodes/${codes}`));
      
      return await data;
    }

    // const getContactsByPage = async (pagination?: IPagination) : Promise<IContact[]> => {
    //   const pageSize = pagination?.pageSize ?? 50;
    //   const pageNumber = pagination?.pageNumber ?? 1;

    //   const {data} = (await axios.get(`/api/production/contact/get-contacts-by-page?pageSize=${pageSize}&pageNumber=${pageNumber}`));
    //   return await data;
    // }

    
      
    return {    
      getMainInformations,
      getImages4Carousel,
      getSlideImages,

      getValueDefinitions,
      getValueKids,

      getSpecialOfferDefintions,
      getSpecialOffers,

      getAdviceDefinitions,
      getAdvices,

      getHomeTops,

      getBestsellersDefinitions,
      getBestsellers,

      getGaleryPhotos,

      getServices,

      getExpeditionModes,

      getLongTexts,

      getPaymentModeSecures,

      getEnumerationItemsByEnumerationCodes

    } 
}

export default _;

