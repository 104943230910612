
import { useState } from "react";

import {RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { useSetRecoilState, useRecoilState } from "recoil"; 

import { IEntity, IEntityProperty, IFeatureDescription, IFeatureParameter, IReportDescription } from "library/interface";

import useAxios from 'library/axios'; 

import { globalConfig } from 'config';
// import { IFeatureDescription, IFeatureParameter } from "../utils/interface";

const _ = () => {

    const axios = useAxios();   

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const retrieveEntity = async (entityName: string, entityId: number)  =>  {
        const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/home/v1/${globalConfig.get().applicationApiToken}/retrieve-entity?entityName=${entityName}&entityId=${entityId}`));
        return await data;
      }
  
      const retrieveData = async ( entityName: string, entityId: number,
          refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<unknown,unknown>>
      ) : Promise<boolean> => {
        if( (entityId || 0) <= 0) 
            return false;
        //   enqueueSnackbar( `${t('Invalid Id')} : ${_id}`, { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      
        if(false) { //if(!canRetreive())
            alert('Can retrieve !!!');
            return false;
            //navigate(`/entity/Enumeration/${entityId}`);
        } else {
          
          await refetch();
          return true;
        }
      }

    return {    
        retrieveEntity,
        retrieveData,
        
    } 
}

export default _;