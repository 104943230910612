import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { FC , MouseEvent, useEffect} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { currentFormNameAtom, currentUserSessionAtom, hubConnectionIdAtom } from 'library/store';

import useVisibilityToggle from 'library/useVisibilityToggle';

import useAxios from 'library/axios';
import { IPasswordChange, IUserCredential, IUserSession, defaultPasswordChange } from './models/User';

import useIdentityUserService from './services/User';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';
import { useLocalStorage } from 'react-use';
import { IResult } from 'library/interface';

export const LoginForm = () => {

  const axios = useAxios();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const {changePassword, postCredential} = useIdentityUserService();
  const { show: showPassword, toggleVisibility: togglePasswordVisibility } = useVisibilityToggle();

  const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);

  const [ storeTenant, setStoreTenant, removeStoreTenant] = useLocalStorage<string>('Tenant');

  const methods = useForm<IUserCredential>({defaultValues: {language: 'fr', userName: currentUserSession.userName, password: '' } });
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const queryClient = useQueryClient();

  


  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IUserSession>,Error,IUserCredential>(postCredential, 
    {        
      onSuccess: (data: IResult<IUserSession>) => {
        
        if(!data.succeeded) {
          enqueueSnackbar( data.messages.join( '\n'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
          
        if(!data.data.isAuthenticated) {
          enqueueSnackbar( t('Invalid user credential'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
        
          enqueueSnackbar( t('Successufully authentify !!!'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
         
        setCurrentUserSession({...currentUserSession, 
          token: data.data.token,
          isAuthenticated: true, id: data.data.id});
        if(!currentUserSession.isAuthenticationInitiatedByUser)
          navigate('/cartConfirm');
        else
          navigate('/');
        // reset(data);
        // queryClient.invalidateQueries(['BankPolicy',data.policyID]);
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      }
    });

    useEffect( () => {        
      setCurrentFormNameAtom(t('Change password'));
      
    }, []);     

  const postLogin = async (event: MouseEvent<HTMLButtonElement>) => {        
    event.preventDefault();


    const tenantAlias = '';
    
    const  {userName, password, language } = getValues();
    const {hostname, host} = window.location;

    mutate( { userName, password: md5(password), language, tenantAlias,  hubConnectionId } );
}

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
          <Grid container rowSpacing={3} columnSpacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={6} >                        
              <Stack flexDirection='column' padding={0} >
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                    {t("Se connecter")}
                  </Typography>
                </Box>
                
                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                  <Typography variant="h6" sx={{...typographySmallGroupBoxStyling}}>
                    {currentUserSession.userName}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} >                                       
                  <TextField  sx={{width:'calc(90% - 8px)'}} id="password" label={t('Mot de passe')} 
                    {...register('password')} type={showPassword ? 'text' : 'password'}
                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} /> 
                </Box>
                <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >                  
                        
                  <Button variant="contained" onClick={postLogin}
                          sx={{                              
                              marginTop:'8px',  marginRight: '16px' ,fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        Me connecter
                  </Button> 
                  <Button variant="outlined" onClick={() => {navigate('/authentication')}}
                          sx={{                              
                              marginTop:'8px',  marginRight: '16px' ,fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        Modifier
                  </Button>   
                  <Button variant="outlined" onClick={() => {navigate('/resetPwd')}}
                          sx={{                              
                              marginTop:'8px',fontFamily: 'Poppins !important', 
                              borderRadius: '5' }} >
                        Mot de passe oublié
                  </Button>     
                </Box>
              </Stack>                        
            </Grid>
              
          </Grid>
      </Box>
    </FormProvider> 
  )
}

