
export interface IMainInformation {
    id: number,
     
    portable1: string,
    logo1: string,
    logo1_Url: string,
    logo2: string,
    logo2_Url: string,
    email1: string,
    horaire1: string,
    lienFacebook: string,
    lienTwitter: string,
    lienInstagram: string,
    lienLinkedIn: string,
    lienWhatsApp: string,
    localisation: string,
    titreBienvenue: string,
    titreKiddies: string,
    descriptionBienvenue: string,

    imageApropos: string,
    imageApropos_Url: string,
    texteApropos: string,

    texteTop: string,

    couleurEnteteTop: string,
    couleurTexteEnteteTop: string,
    couleurBoutonPanier: string,
    couleurTexteBoutonPanier: string,

    couleurBaseBottom: string,
    couleurTexteBaseBottom: string,

    couleurBaseBottomSecure: string,

    texteBoutonPanier: string,
    couleurBadgePanier: string,
    couleurTexteBadgePanier: string,

    montantMinPourLivraisonGratuite: number,
    couleurBoutonValiderPanier: string,
    couleurTexteBoutonValiderPanier: string,
    texteBoutonValiderPanier: string,
    texteBoutonSupprimerDuPanier: string,
    couleurTexteBoutonSupprimerDuPanier: string,
    texteTop2: string,
    texteTop3: string,

    titreIngredientProduit: string,
    titreConseilUtilisationProduit: string,
    couleurTexteIngredientProduit: string,
    couleurTexteConseilUtilisationProduit: string,


    imageMenuGamme: string,
    imageMenuGamme_Url: string,
    imageMenuProduit: string,
    imageMenuProduit_Url: string,
    imageMenuAccessoires: string,
    imageMenuAccessoires_Url: string,
    imageMenuRobesWax: string,
    imageMenuRobesWax_Url: string,
    imageMenuApropos: string,
    imageMenuApropos_Url: string,
    imageMenuNousContacter: string,
    imageMenuNousContacter_Url: string,
}

export interface IIMage4Carousel {
    id: number,
     
    image: string, 
    image_Url: string, 
    titrePrincipal: string, 
    titreSecondaire: string
}

export interface ISlideImage {
    id: number,
     
    image: string, 
    image_Url: string,   
    description: string  
}

export interface IValueDefintion {
    id: number,

    titreGlobal: string,
    titreListe: string,
    titreQuestion: string,

    image: string,
    image_Url: string
}

export interface IValueKid {
    id: number,

    principale: string,
    secondaire: string,

    image: string,
    image_Url: string
}

export interface IBestsellersDefintion {
    id: number,

    titreGlobal: string,
    titre: string    
}

export interface IBestsellers {
    id: number,

    nom: string,
    description: string,
    prix: number,
    avis: number,
    
    image: string,
    image_Url: string
}


export interface ISpecialOfferDefintion {
    id: number,

    titreGlobal: string,
    titre: string,    
}

export interface ISpecialOffer {
    id: number,

    nom: string,
    description: string,    
    desc1: string,    
    desc2: string,    
    desc3: string,    
    desc4: string,    
    desc5: string,  
    
    image: string,
    image_Url: string
}


export interface IAdviceDefinition {
    id: number,

    titreGlobal: string,
    titre: string,   
}

export interface IAdvice {
    id: number,

    nom: string,
    description: string,   
    auteur: string,   
    datePublication: Date,

    image: string,
    image_Url: string 
}

export interface IHomeTop {
    id: number,

    titre: string,
    description: string,    
     
    image: string,
    image_Url: string,

    imageEx1: string,
    imageEx1_Url: string,
    imageEx2: string,
    imageEx2_Url: string,
    imageEx3: string,
    imageEx3_Url: string, 
    imageEx4: string,
    imageEx4_Url: string 
}

export interface IGaleryPhoto {
    id: number,
  

    image: string,
    image_Url: string 
}

export interface IExpeditionMode {
    id: number,  

    productId: number,

    nom: string,
    description: string,
    prix: number
}

export interface ILongText {
    id: number,
    texte: string
}

export interface IPaymentModeSecure {
    nom: string,
    logo: string,
    logo_Url: string
}


export const defaultMainInformation : IMainInformation = {
    id: 0,
     
    portable1: '',
    logo1: '',
    logo1_Url: '',
    logo2: '',
    logo2_Url: '',
    email1: '',
    horaire1: '',
    lienFacebook: '',
    lienTwitter: '',
    lienInstagram: '',
    lienLinkedIn: '',
    lienWhatsApp: '',
    localisation: '',
    titreBienvenue: '',
    titreKiddies: '',
    descriptionBienvenue: '',

    imageApropos: '',
    imageApropos_Url: '',
    texteApropos: '',

    texteTop: `Livraison gratuite des 60€ d’achats en France métropolitaine`,

    couleurEnteteTop: '#1AAF9D',
    couleurTexteEnteteTop: '#FFFFFF',
    couleurBoutonPanier: '#E9645F',
    couleurTexteBoutonPanier: '#E9645F',

    couleurBaseBottom: '#000000',
    couleurTexteBaseBottom: '#FFFFFF',

    couleurBaseBottomSecure: '#000000',

    texteBoutonPanier: 'Ajouter au panier',
    couleurBadgePanier: '#1AAF9D',
    couleurTexteBadgePanier: '#FFFFFF',

    montantMinPourLivraisonGratuite: 100,
    couleurBoutonValiderPanier: '#E9645F',
    couleurTexteBoutonValiderPanier: '#FFFFFF',
    texteBoutonValiderPanier: 'Valider le panier',
    texteBoutonSupprimerDuPanier: 'Supprimer',
    couleurTexteBoutonSupprimerDuPanier: '#E9645F',
    texteTop2: 'Message top 2',
    texteTop3: '',

    titreIngredientProduit: 'Ingredients',
    titreConseilUtilisationProduit: `Conseils d'utilisation`,
    couleurTexteIngredientProduit: `#1AAF9D`,
    couleurTexteConseilUtilisationProduit: '#1AAF9D',

    imageMenuGamme: '',
    imageMenuGamme_Url: '',
    imageMenuProduit: '',
    imageMenuProduit_Url: '',
    imageMenuAccessoires: '',
    imageMenuAccessoires_Url: '',
    imageMenuRobesWax: '',
    imageMenuRobesWax_Url: '',
    imageMenuApropos: '',
    imageMenuApropos_Url: '',
    imageMenuNousContacter: '',
    imageMenuNousContacter_Url: '',

}

export const defaultIMage4Carousel : IIMage4Carousel = {
    id: 0,
     
    image: '', 
    image_Url: '', 
    titrePrincipal: '', 
    titreSecondaire: ''
}

export const defaultValueDefintion : IValueDefintion = {
    id: 0,

    titreGlobal: '',
    titreListe: '',
    titreQuestion: '',

    image: '',
    image_Url: ''
}

export const defaultSpecialOfferDefintion :  ISpecialOfferDefintion = {
    id: 0,

    titreGlobal: '',
    titre: '',    
}

export const defaultAdviceDefinition : IAdviceDefinition = {
    id: 0,

    titreGlobal: '',
    titre: '',  
}

export const defaultHomeTop : IHomeTop = {
    id: 0,

    titre: '',     
    description: '',    
    
    image: '',
    image_Url: '',
    
    imageEx1: '',
    imageEx1_Url: '',
    imageEx2: '',
    imageEx2_Url: '',
    imageEx3: '',
    imageEx3_Url: '', 
    imageEx4: '',
    imageEx4_Url: '' 
}

export const defaultBestsellersDefintion : IBestsellersDefintion = {
    id: 0,

    titreGlobal: '',
    titre: '',  
}


export const defaultExpeditionMode :  IExpeditionMode = {
    id: 0,  

    productId: 0,

    nom: '<< AUCUN >>',
    description: '<< AUCUN >>',
    prix: 0
}