import React, { useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  Card, CardActionArea, CardMedia, CardContent, Rating, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import Slider from 'react-slick';

const Bestsellers = () => {

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getBestsellersDefinitions, getBestsellers } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: bestsellersDefinitions} = useQuery<IBestsellersDefintion[]>( ['BestsellersDefinition'], () => getBestsellersDefinitions());
  const {data: bestsellers} = useQuery<IBestsellers[]>( ['Bestsellers'], () => getBestsellers());

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

  const [bestsellersDefinition, setBestsellersDefinition] = useState<IBestsellersDefintion>(defaultBestsellersDefintion);
  useEffect(() => {
    
      if(!isFalsy(bestsellersDefinitions) && bestsellersDefinitions?.length>0)
        setBestsellersDefinition(bestsellersDefinitions[0]);
}, [bestsellersDefinitions]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2} textAlign="center">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h3" {...typographySmallHandWriting}> 
                  {bestsellersDefinition.titreGlobal}
                </Typography>                
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {bestsellersDefinition.titre}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              
            </Stack>
          </Grid> 
          <Grid item xs={12}>            
            <Slider {...sliderSettings}>
              {(bestsellers || []).map( (sellItem, idx) => 
                (
                
                  <div key={`car ${idx} ${sellItem.description}`}>
                    
                  <Card sx={{ display: 'inline-block' }}>
                    <CardActionArea>
                      <CardMedia 
                        sx={{ height: 350 }}
                        image={`${globalConfig.get().apiUrl}/download/${sellItem.image_Url}`}
                        title={sellItem.nom}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" sx={{ fontFamily: 'Poppins', alignItems:'center' }}>
                          {sellItem.nom}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" sx={{ fontFamily: 'Poppins' }}>
                          {sellItem.description}
                        </Typography>
                        <Typography variant="h6" sx={{ fontFamily: 'Poppins', marginTop: '10px' }}>
                          {sellItem.prix} €
                        </Typography>
                        <Rating name="product-rating" value={sellItem.avis} precision={0.5} readOnly /> 
                      </CardContent>
                    </CardActionArea>
                  </Card>
                
                  </div>
                ) 
              )}      
            </Slider>            
          </Grid>   
        </Grid>
        
    </Box>
  );
};


export default Bestsellers;
