
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LinearProgress from '@mui/material/LinearProgress';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

//import { ChromePicker, ColorResult } from 'react-color';


//import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom, cartAtom } from 'library/store';
import useCartService, { useBasicFilterCart } from './services/Cart';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme, CircularProgress } from '@mui/material';
//import NumberFormat from 'react-number-format';

import { ICart,  defaultCart } from './models/Cart';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

//import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';

// import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
//       Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
//import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage, typographyBigGroupBoxStyling, typographySmallGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
//import { IArticleStock } from 'features/configuration/models/Delivery';

import { globalConfig } from 'config';
import { addMinutes } from 'date-fns';
import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import { Enum_ARTICLE_OPTION, IEnumerationItem } from 'features/configuration/models/Enumeration';

type ExtensionUsage = 'content-A' | 'content-U';

export const CartForm = () => {


  const [cart, setCart] = useRecoilState(cartAtom);
  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  
  const { getMainInformations, getEnumerationItemsByEnumerationCodes } = useMainInformation();

  const { removeToCart } = useCart();

  //const {data: services} = useQuery<IService[]>( ['Service'], () => getServices());


  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );


  //const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

//   const {retrieveEntity, retrieveData, openEntityActionDrawer, 
//       checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  //const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createCart, updateCart } = useCartService();

  const {range} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCart = useBasicFilterCart( 
    (event: React.MouseEvent<unknown>, row: ICart) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

//   const [openEntityFilter, setOpenEntityFilter] = useState(false);
//   const basicFilterEntity = useBasicFilterEntity( 
//       (event: React.MouseEvent<unknown>, row: IEntity) => {
//           const {name, description} = row;

                                    
//           setOpenEntityFilter(false);
//       }
//   );

//   const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
//   const basicFilterEnumeration = useBasicFilterEnumeration( 
//       (event: React.MouseEvent<unknown>, row: IEnumeration) => {
//           const {id, name, description} = row;

//           // setValue('enumerationId', id);
//           // setValue('enumerationName', name);
                           
//           setOpenEnumerationFilter(false);
//       }
//   );


  

  const methods = useForm<ICart>({defaultValues: {...cart, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

 
  const normaliseProgressValue = (value: number, maxValue: number) =>  (maxValue===0 || maxValue<=value)?100: (value* 100) / (maxValue);

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  
//   const watchName = watch('name');    
//   const watchDescription = watch('description');    
//   const watchType = watch('type');    
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());  

  const {data: enumItems} = useQuery<IEnumerationItem[]>(
    ['EnumerationItems', Enum_ARTICLE_OPTION], () => getEnumerationItemsByEnumerationCodes( [Enum_ARTICLE_OPTION ] ));


  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICart>,Error,ICart>(
      _id>0?updateCart:createCart, {   
        onSuccess: (data: IResult<ICart>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Cart')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Cart',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    // const {data: _data, refetch} = useQuery<ICart>(['Cart', _id], () => retrieveEntity('Cart',_id), 
    //   {refetchOnWindowFocus: false ,enabled: false } );

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Cart'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
      //         Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
      //         Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ] ));
 
  
    
function openFileDialog() {
  (document as any).getElementById("file-upload").click();
}

const setFile = (_event: any) => {
  let f = _event.target.files![0];

  const fileSizeInKB = f.size / 1024;
  // Check if the file size is within your limit (e.g., 200 KB)
  if (fileSizeInKB > 200) {
    alert(t('File size should be less than 200 KB'));
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {

      var binaryString = reader.result as string;
  
      const base64String = binaryString
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');

    //   setValue("base64File", base64String);
    //   setValue("fileName", f.name);
    };

    reader.onerror = function () {
      console.log("File load failed");
    };    
    reader.readAsDataURL(f);    
};

const handleCartValidate = async (event: MouseEvent<HTMLButtonElement>) => {
  if(currentUserSession.isAuthenticated)
    navigate('/cartConfirm');
  else {
    setCurrentUserSession({...currentUserSession, isAuthenticationInitiatedByUser: false});
    navigate('/authentication');
  }  
 } 

const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
  }, [mainInformations]);

  useEffect(() => {
    
    const subscription = watch( async (value, { name, type }) => {
      
      if(isFalsy(value)) return;

      if( name?.startsWith('cartProducts') && name?.endsWith('quantity') ) {
        
        const curCart = value as ICart;
        const cartProducts = curCart.cartProducts.map( (cartProduct) => (
            {...cartProduct, netAmount: cartProduct.quantity*cartProduct.unityAmount }) );
        
        setCart( {...cart,
          netAmount: sum( cartProducts.map(cartProduct => cartProduct.netAmount) ),
          cartProducts} );
      } 
    });  

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(cart);
    //setQuantity( cart.cartProducts.reduce((acc, product) => acc + product.quantity, 0) );
}, [cart, cart.cartProducts, reset]);



    // const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    // useEffect( () => {
      
    //   async function _() {   
    //     if(_data && _data.type === 'article' && _data.id > 0) {         
    //       const stocks = await getArticlePerStores( 0, _id);
    //       setArticleStocks(stocks);
    //     }
    //   }
    //   _();  
    // }, [_data] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('Cart'));
        setCurrentBasicTextFilterProps(basicFilterCart);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        // useEffect( () => {
        //     // setCurrentFormName(t('Billing'));        
        //     setCurrentFormNameAtom(_id>0?`${t('Cart')} - # ${_id} # -`: t('Cart') );
        //     if(_id > 0)
        //       retrieveData('Cart',_id, refetch);  
        //   }, [_id] );
    
    
        // useEffect( () => {
            
        // if(_data && _data.id > 0) {
        //     reset(_data);
        // }
        // }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultCart });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        // if(!checkEntitySaveAuthorization('Cart', _id)) {
        //   setIsSaveLoading(false);
        //      return;
        // }          
  
        const data = getValues(); 

        // if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
        //   enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
        //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }

                
        if(data.firstName.trim() === '' || data.lastName.trim() === '') {
            enqueueSnackbar( t('Name is required'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          if(data.portable.trim() === '') {
            enqueueSnackbar( t('Portable is required'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

              
        mutate({...data           
           
         });
      }

     
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        //openEntityActionDrawer('Cart', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                  <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                    <Stack flexDirection='column'  >                      
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                          <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnSaveCart' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                          <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                            {cart.cartProducts.length > 0 && t('Kiddies Care')}
                            </Typography>
                          </Box>                                                                        
                      </Box>                      
                    </Stack>                        
                  </Grid>
                  { cart.cartProducts.length > 0 && <Grid item xs={12} sm={12} md={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('PANIER')}
                        </Typography>
                      </Box>
                      {  getValues().cartProducts.map( (cartProduct,ix) => { 
                        const filterOptions = (refEnumItems.current || []).filter(x => x.enumerationCode === Enum_ARTICLE_OPTION
                                  && x.parentEnumerationItemCode === cartProduct.productFilterOption);   
                        const findEnumArticleOption = filterOptions.find(x => x.code === cartProduct.articleOption);                                      
                        return (
                        <Box sx={{ mt: 1, width: '100%', display: 'flex'}} key={` product ${cartProduct.contentId} ${ix} `}>
                          <Stack flexDirection='row' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                            <Box sx={{ mt: 1, width: '40%', display: 'flex'}}>
                              <div style={{
                                outline: 'none',
                                border: 'none',
                                width: '100%',
                                height: '150px',
                                borderRadius: '10%',
                                overflow: 'hidden',
                                margin: '0 auto'
                                } }>
                                <img src={`${globalConfig.get().apiUrl}/download/${cartProduct.productImage_Url}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                              </div>
                            </Box>
                            <Box sx={{ mt: 1, width: '60%', display: 'flex'}}>
                              <Stack flexDirection='column' sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                   {cartProduct.productName}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                  {cartProduct.productDescription}
                                  </Typography>
                                </Box>
                                { filterOptions.length>1 && !isFalsy(findEnumArticleOption) &&
                                <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                                  {findEnumArticleOption.name}
                                  </Typography>
                                </Box> }
                                <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                  <Controller 
                                    name={`cartProducts.${ix}.quantity`}
                                    control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select variant='outlined' onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="contractScope"
                                            label={t('')} inputProps={ {readOnly: false}} >
                                            <MenuItem key={1} value={1}>{1}</MenuItem>
                                            <MenuItem key={2} value={2}>{2}</MenuItem>
                                            <MenuItem key={3} value={3}>{3}</MenuItem>
                                            <MenuItem key={4} value={4}>{4}</MenuItem>
                                            <MenuItem key={5} value={5}>{5}</MenuItem>
                                          </TextField>
                                        )}
                                    />
                                  <Button variant='text' onClick={() => {removeToCart(cartProduct.contentId)}  }
                                    sx={{
                                        //background: mainInformation.couleurBoutonPanier, 
                                        //backgroundImage: 'linear-gradient(to right, #F26666, #400C07)',
                                        color: mainInformation.couleurTexteBoutonSupprimerDuPanier, 
                                        marginLeft:'12px', marginTop:'8px', fontFamily: 'Poppins !important', borderRadius: '5px' }} >
                                          {mainInformation.texteBoutonSupprimerDuPanier}
                                  </Button>
                                  <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                                   {cartProduct.netAmount.toFixed(2)} €
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>)
                      }) }
                    </Stack>
                  </Grid> }
                  {cart.cartProducts.length > 0 && <Grid item xs={12} sm={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h1" sx={{...typographyBigGroupBoxStyling}}>
                        {t('Avez vous un code promo ?')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TextField sx={{width:'calc(50% - 8px)'}} id="firstName" label={t('Code promo')} {...register('promotionCode')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                        <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                            label={`Utiliser le code ?`}
                            control={
                              <Controller
                                name={`usePromotionCode`}
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyBigGroupBoxStyling}}>
                        {t('Recapitulatif')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                          {t('Sous total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().netAmount.toFixed(2)}  €
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                          {t('Livraison')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {t("Calculé à l'étape paiement")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%',
                             marginLeft: '20px', marginRight: '10px', background: 'lightgray'}}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>      
                          <Box sx={{ minWidth: 35 }}><Typography sx={{ ...typographySmallGroupBoxStyling, textAlign: 'left',mr: 1, ml: 1 }}>0 €</Typography></Box>
                          <Box sx={{ width: '100%', padding: '10px', background: 'lightgray'}}>
                            <LinearProgress variant="determinate" value={normaliseProgressValue(getValues().netAmount, mainInformation.montantMinPourLivraisonGratuite)} sx={{
                                height: '6px',
                                backgroundColor: 'lightgray', // Background color
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: 'green', // Bar color
                                },
                              }}/>    
                          </Box>
                          <Box sx={{ minWidth: 80 }}><Typography sx={{ ...typographySmallGroupBoxStyling, textAlign: 'left',mr: 1, ml: 1 }}>{mainInformation.montantMinPourLivraisonGratuite.toFixed(2)} €</Typography></Box>
                        </Box>
                        <Box sx={{ width: '100%', mt: 1 }}>
                          <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'left', flexGrow: 1}}>
                            {getValues().netAmount>= mainInformation.montantMinPourLivraisonGratuite ? 
                              t('Vous bénéficiez de la livraison offerte !') : 
                              t(`Plus que ${(mainInformation.montantMinPourLivraisonGratuite-getValues().netAmount).toLocaleString()} € pour bénéficier de la livraison gratuite`)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('Total')}
                        </Typography>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling, textAlign: 'right', flexGrow: 1}}>
                          {getValues().netAmount.toFixed(2)}  €
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button variant="contained" onClick={handleCartValidate}
                            sx={{
                                background: mainInformation.couleurBoutonValiderPanier, 
                                //backgroundImage: 'linear-gradient(to right, #F26666, #400C07)',
                                color: mainInformation.couleurTexteBoutonValiderPanier, 
                                marginTop:'15px',fontFamily: 'Poppins !important', width:'80%', height:'45px', 
                                borderRadius: '30px' }} >
                          {mainInformation.texteBoutonValiderPanier}
                        </Button>
                      </Box>
                    </Stack>
                  </Grid> }   
                  { cart.cartProducts.length === 0 && <Grid item xs={12} sm={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h1" sx={{  transform: 'skewY(-15deg)', color: 'red', fontWeight: '14px' }}>
                        {t('Oups !!! ?')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2, mb: 4, width: '100%', display: 'flex'}}>
                        <Typography variant="h3" sx={{  transform: 'skewY(-15deg)', fontWeight: '10px' }}>
                        {t('Votre panier est vide')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid> }
                  { cart.cartProducts.length === 0 && <Grid item xs={12} sm={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Stack flexDirection='column'>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Typography variant="h6" sx={{...typographyGroupBoxStyling}}>
                        {t('Connectez-vous pour retrouver votre panier')}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                        <Button variant="contained" onClick={() => {navigate('/authentication')}  }
                          sx={{
                              background: mainInformation.couleurBoutonPanier, 
                              //backgroundImage: 'linear-gradient(to right, #F26666, #400C07)',
                              color: mainInformation.couleurTexteBoutonPanier, 
                              marginTop:'15px',fontFamily: 'Poppins !important', width:'80%', height:'45px', 
                              borderRadius: '5px' }} >
                                Me connecter
                        </Button>
                      </Box>
                    </Stack>
                  </Grid> }  
                                                          
                </Grid>
                
            </Box>
        </FormProvider> 
  )
}

