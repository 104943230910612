import React, { FC, useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,  
    Link, Card, CardActionArea, CardMedia, CardContent, Rating, } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';


import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { IBestsellers, IBestsellersDefintion, IMainInformation, ISlideImage, defaultBestsellersDefintion, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';

import useCart from 'features/finance/services/Cart';
import useProduct from 'features/production/services/Product';

import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import { typographyBigGroupBoxStyling, typographyDescription, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import Slider from 'react-slick';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';
import { IProduct, defaultProduct } from './models/Product';
import { useParams } from 'react-router-dom';
import { defaultCartProduct } from 'features/finance/models/Cart';


export interface ProductDetailFormProps {
  lineOfBusinessCode: string
}

export const ProductDetailForm: FC<IProduct> = (props: IProduct = defaultProduct) => {

  //const {lineOfBusinessCode} = props;

  const {id} = useParams();

  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getMainInformations, getBestsellersDefinitions, getBestsellers } = useMainInformation();

  const { getProducts } = useProduct();

  const { addToCart } = useCart();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
  const {data: bestsellersDefinitions} = useQuery<IBestsellersDefintion[]>( ['BestsellersDefinition'], () => getBestsellersDefinitions());
  //const {data: bestsellers} = useQuery<IBestsellers[]>( ['Bestsellers'], () => getBestsellers());

  const {data: products} = useQuery<IProduct[]>( ['Products'], () => getProducts({name: '', description: ''}));

  

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);

const [product, setProduct] = useState<IProduct>(props);
useEffect(() => {
  
  
    if(!isFalsy(products) && products?.length>0) {
      console.log(_id); console.log(products);
      const prod = products.find(x => x.id === _id);
      if(isFalsy(prod)) return;

      setProduct(prod!);
    }
      
}, [products, _id]);

//   const [bestsellersDefinition, setBestsellersDefinition] = useState<IBestsellersDefintion>(defaultBestsellersDefintion);
//   useEffect(() => {
    
//       if(!isFalsy(bestsellersDefinitions) && bestsellersDefinitions?.length>0)
//         setBestsellersDefinition(bestsellersDefinitions[0]);
// }, [bestsellersDefinitions]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box bgcolor="white" color="back" py={0.25} px={2} textAlign="center">
        <Grid container justifyContent="space-between" alignItems="top" spacing={1}>
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography component="h1"  {...typographyBigGroupBoxStyling}>
                  {/* {bestsellersDefinition.titre} */}
                  {t("Dans l'univers du bien-être")}
                </Typography>
              </Box>
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}> 
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%' }} />                  
                  <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo1_Url}`} height={20} width={20} alt='Logo' />                  
                  <Divider sx={{ borderColor: 'primary.main', mx: 2, width: '20%', }} />
              </Box>
              
            </Stack>
          </Grid> 
                      
                <Grid item xs={12} sm={12} md={6} key={`car 0`}>
                  <Stack flexDirection='column' textAlign="center" 
                      sx={ { padding: 0.25, marging: 1, //borderTopLeftRadius: '15%', borderTopRightRadius: '15%',  
                        borderRadius: '5%',
                        background: '#ffffff',
                        backgroundImage: 'none',
                        color: 'black',
                        transition: 'background-image 0.3s ease',
                        //height: '450px',   
                        width: '95%',  
                        //border: '2px solid #1AAF9D'                   
                        } }>
                    <Box sx={{ mt: 1, p:0.25, width: '100%', display: 'flex', justifyContent: 'center', }}>                       
                      { (!isFalsy(product.otherImage_Url) && product.image_Url !==  product.otherImage_Url ) ?
                        <Carousel showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} 
                            infiniteLoop={true} autoPlay={true} interval={2000} stopOnHover={true} emulateTouch={true}
                            >
                              {[product.image_Url, product.otherImage_Url].filter( x => !isFalsy(x)).map((imageUrl, idx) => 
                                (
                                  <div key={` ${imageUrl} -${idx}`} style={{
                                    outline: 'none',
                                    border: 'none',
                                    width: '100%',
                                    //height: '400px', ==
                                    borderRadius: '10%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                    } }>
                                    <img src={`${globalConfig.get().apiUrl}/download/${imageUrl}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                                  </div>
                                )
                              )}
                        </Carousel> :
                        <div style={{
                          outline: 'none',
                          border: 'none',
                          width: '100%',
                          // height: '400px', ==
                          borderRadius: '10%',
                          overflow: 'hidden',
                          margin: '0 auto'
                          } }>
                          <img src={`${globalConfig.get().apiUrl}/download/${product.image_Url}`} alt="..." style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '5%'  }}/>
                        </div>
                      }
                    </Box>
                    
                  </Stack>
                </Grid>
              
                <Grid item xs={12} sm={12} md={6} key={`car `}>
                  <Stack flexDirection='column' textAlign="center" 
                      sx={ { padding: 0.25, marging: 1, //borderTopLeftRadius: '15%', borderTopRightRadius: '15%',  
                        borderRadius: '5%',
                        background: '#ffffff',
                        backgroundImage: 'none',
                        color: 'black',
                        transition: 'background-image 0.3s ease',
                        //height: '450px',   
                        width: '95%',  
                        //border: '2px solid #1AAF9D'                   
                        } }>
                    
                    <Box sx={{ mt: 0.25, mb: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: '#1AAF9D'}}>
                        {product.name}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h3" {...typographyDescription}>
                        {product.description}
                      </Typography>
                    </Box>
                    { !isFalsy(product.conseilUtilisation) && <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'left'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: mainInformation.couleurTexteIngredientProduit}}>
                        {mainInformation.titreIngredientProduit}
                      </Typography>
                    </Box> }
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h3" {...typographyDescription}>
                        {product.ingredient}
                      </Typography>
                    </Box>
                    { !isFalsy(product.conseilUtilisation) && <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'left'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: mainInformation.couleurTexteConseilUtilisationProduit}}>
                        {mainInformation.titreConseilUtilisationProduit}
                      </Typography>
                    </Box> }
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h3" {...typographyDescription}>
                        {product.conseilUtilisation}
                      </Typography>
                    </Box>
                    {/* <Box sx={{ mt: 0.25, height: '55px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Rating name="product-rating" value={product.rating} precision={0.5} readOnly />
                    </Box> */}
                    <Box sx={{ mt: 0.25, height: '55px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography component="h1"  {...typographyGroupBoxStyling} sx={{ color: '#1AAF9D'}}>
                          {product.price.toFixed(2)} €
                        </Typography>
                    </Box>
                    
                    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Button variant="contained" onClick={() => {addToCart(
                                                {...defaultCartProduct, contentId: product.id, productId: product.productId, 
                                                  quantity: 1, articleOption: '',
                                                  unityAmount: product.price, netAmount: product.price,
                                                  productName: product.name, 
                                                  productDescription: product.descriptionReduite, productImage_Url: product.image_Url,
                                                  productWarningAddToCart: product.warningAjoutPannier,
                                                  productFilterOption: product.filterOption })}}
                          sx={{
                              background: mainInformation.couleurBoutonPanier, 
                              //backgroundImage: 'linear-gradient(to right, #F26666, #400C07)',
                              color: mainInformation.couleurTexteBoutonPanier, 
                              marginTop:'15px',fontFamily: 'Poppins !important', width:'80%', height:'55px', 
                              borderRadius: '30px' }} >
                        {mainInformation.texteBoutonPanier}
                      </Button>
                    </Box>
                  </Stack>
                </Grid>            
          
        </Grid>
        
    </Box>
  );
};


